import {DATA_LISTS,SHOP_LISTS,FLOW_DATA,ACTIVITY_LISTS,SHOW_CONSEQUENCE,CREATION_ACTIVITY_ERR,COUPONS_LISTS} from '../actions/types';
import { isUndefined } from 'micro-dash';
import { act } from 'react-dom/test-utils';
import { coupons_lists } from '../actions/payment_data';
const _ = require('micro-dash');

const initialState={
    payment_lists:[],
    page:1,
    count:0,
    shop_lists:[],
    flow_data:'',
    activity_lists:[],
    is_consequence:false,
    err_value:'',
    coupons_lists:[]
}

// eslint-disable-next-line import/no-anonymous-default-export
export default function(state=initialState, action) {
    switch(action.type){
        case DATA_LISTS:
            return {...state,payment_lists:action.results,page:action.page,count:action.count}
        case SHOP_LISTS:
            return {...state,shop_lists:action.message}
        case FLOW_DATA:
            return {...state,flow_data:action.data}
        case ACTIVITY_LISTS:
            return {...state,activity_lists:action.data}
        case SHOW_CONSEQUENCE:
            return {...state,is_consequence:action.is_consequence}
        case CREATION_ACTIVITY_ERR:
            return {...state,err_value:action.err}
        case COUPONS_LISTS:
            return {...state,coupons_lists:action.data}
    default:
        return state;
    }
}