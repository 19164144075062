
import React, { useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import { Redirect } from "react-router-dom";
import Select from '@mui/material/Select';
import { payment_data, shops_lists, payment_summary } from '../../actions/payment_data'
const _ = require('micro-dash');


function createData(name, calories, fat, carbs, protein) {
    return { name, calories, fat, carbs, protein };
}

const rows = [
    createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
    createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
    createData('Eclair', 262, 16.0, 24, 6.0),
    createData('Cupcake', 305, 3.7, 67, 4.3),
    createData('Gingerbread', 356, 16.0, 49, 3.9),
];
function UserData() {
    const dispatch = useDispatch();
    const page_change = (event, value) => {
        //console.log(value)
        setPage(page)
        dispatch(payment_data(value, shop))
    }
    const [page, setPage] = React.useState(1);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = (e) => {
        //console.log(e)
        setAnchorEl(null);
    };
    useEffect(() => {
        if (_.isNil(token)) {
            return <Redirect to={'/login'} />;
        } else {
            dispatch(shops_lists())
        }
        // dispatch(payment_data(1,41))
    }, []);
    const token = useSelector((state) => state.user.token);
    const payment_lists = useSelector((state) => state.payment_data.payment_lists);
    const count = useSelector((state) => state.payment_data.count);
    const shops = useSelector((state) => state.payment_data.shop_lists);
    const flow_data = useSelector((state) => state.payment_data.flow_data);
    // console.log(flow_data)
    const [shop, setShop] = React.useState('');

    const handleChange = (event) => {
        //console.log(event)
        setShop(event.target.value);
        dispatch(payment_data(page, event.target.value))
        dispatch(payment_summary(event.target.value))
    };
    useEffect(() => {
        //console.log(shops)
        if (shops.length > 0) {
            setShop(shops[0].id);
        }
    }, [dispatch, shops]);
    //console.log(payment_lists)
    return (
        <div>
            <div style={{ display: 'flex', paddingBottom: 10 }}>
                {/* <div style={{display:'flex',alignItems:'center',fontSize:14}}>店铺：</div> */}
                <div>
                    <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">店铺</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={shop}
                            label="Age"
                            onChange={handleChange}
                        >
                            {
                                shops.map((item, index) =>
                                    <MenuItem value={item.id} key={index}>{item.name}</MenuItem>
                                )
                            }
                        </Select>
                    </FormControl>
                </div>
                <div style={{display:'flex',width:"100%"}}>
                    <div style={{ paddingLeft:'3%',display: "flex", alignItems: 'center' }}>
                        <div style={{textAlign:'center'}}>
                            <div style={{paddingBottom:5}}>今日收入总额</div>
                            <div>￥{flow_data.today_summary/100}</div>
                        </div>
                    </div>
                    <div style={{ paddingLeft:'3%',display: "flex", alignItems: 'center' }}>
                        <div style={{textAlign:'center'}}>
                            <div style={{paddingBottom:5}}>昨日收入总额</div>
                            <div>￥{flow_data.yesterday_summary/100}</div>
                        </div>
                    </div>
                    <div style={{ paddingLeft:'3%',display: "flex", alignItems: 'center' }}>
                        <div style={{textAlign:'center'}}>
                            <div style={{paddingBottom:5}}>本周收入总额</div>
                            <div>￥{flow_data.week_summary/100}</div>
                        </div>
                    </div>
                    <div style={{ paddingLeft:'3%',display: "flex", alignItems: 'center' }}>
                        <div style={{textAlign:'center'}}>
                            <div style={{paddingBottom:5}}>上周收入总额</div>
                            <div>￥{flow_data.last_week_summary/100}</div>
                        </div>
                    </div>
                    <div style={{ paddingLeft:'3%',display: "flex", alignItems: 'center' }}>
                        <div style={{textAlign:'center'}}>
                            <div style={{paddingBottom:5}}>今日美团数据</div>
                            <div>￥{flow_data.today_summary_meituan/100}</div>
                        </div>
                    </div>
                    <div style={{ paddingLeft:'3%',display: "flex", alignItems: 'center' }}>
                        <div style={{textAlign:'center'}}>
                            <div style={{paddingBottom:5}}>昨日美团数据</div>
                            <div>￥{flow_data.yesterday_summary_meituan/100}</div>
                        </div>
                    </div>
                    <div style={{ paddingLeft:'3%',display: "flex", alignItems: 'center' }}>
                        <div style={{textAlign:'center'}}>
                            <div style={{paddingBottom:5}}>今日抖音数据</div>
                            <div>￥{flow_data.today_summary_douyin/100}</div>
                        </div>
                    </div>
                    <div style={{ paddingLeft:'3%',display: "flex", alignItems: 'center' }}>
                        <div style={{textAlign:'center'}}>
                            <div style={{paddingBottom:5}}>昨日抖音数据</div>
                            <div>￥{flow_data.yesterday_summary_douyin/100}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <TableContainer component={Paper} style={{ height: '70vh' }}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>房间名</TableCell>
                                <TableCell align="center">支付时间</TableCell>
                                <TableCell align="center">总价</TableCell>
                                <TableCell align="center">现金支付</TableCell>
                                <TableCell align="center">积分支付</TableCell>
                                <TableCell align="center">信息</TableCell>
                                <TableCell align="center">用户id</TableCell>
                                <TableCell align="center">手机号</TableCell>
                                <TableCell align="center">类别</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {payment_lists.map((row) => (
                                <TableRow
                                    key={row.uid}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell component="th" scope="row">
                                        {row.room_name}
                                    </TableCell>
                                    <TableCell align="center">{row.create_time}</TableCell>
                                    <TableCell align="center">￥{row.price / 100}</TableCell>
                                    <TableCell align="center">￥{row.cash_amount / 100}</TableCell>
                                    <TableCell align="center">￥{row.integral_amount / 100}</TableCell>
                                    <TableCell align="center">{row.extra_info}</TableCell>
                                    <TableCell align="center">{row?.user?.id}</TableCell>
                                    <TableCell align="center">{row?.user?.phone_number}</TableCell>
                                    <TableCell align="center">{
                                        (() => {
                                            if (row.scenario_type === 0) {
                                                return '预约'
                                            } else if (row.scenario_type === 1) {
                                                return '续约'
                                            } else if (row.scenario_type === 2) {
                                                return '充值'
                                            } else if (row.scenario_type === 3) {
                                                return '退款'
                                            } else if (row.scenario_type === 4) {
                                                return '美团充值'
                                            }else if(row.scenario_type === 5){
                                                return '分账'
                                            }else if(row.scenario_type === 6){
                                                return '抖音充值'
                                            }
                                        })()
                                    }</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <div style={{ paddingTop: 20, display: 'flex', justifyContent: 'center' }}>
                    <Stack spacing={2}>
                        <Pagination count={Math.ceil(count / 10)} onChange={page_change} />
                    </Stack>
                </div>
            </div>
        </div>
    )
}
export default UserData;