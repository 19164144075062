import {RELEVANCY,SNACK_OPEN,LOADINGN_OPEN,BOSS_LISTS,CLEANERS_LISTS
} from '../actions/types';
import { isUndefined } from 'micro-dash';
import { act } from 'react-dom/test-utils';
const _ = require('micro-dash');

const initialState={
    facility_lists:[],
    loading_open:false,
    snack_open:false,
    is_relevancy:true,
    boss_lists:[],
    cleaners_lists:[]
}

// eslint-disable-next-line import/no-anonymous-default-export
export default function(state=initialState, action) {
    switch(action.type){
        case RELEVANCY:
            return {...state,is_relevancy:action.mess,snack_open:true}
        case SNACK_OPEN:
            return {...state,snack_open:false}
        case LOADINGN_OPEN:
            return {...state,loading_open:action.loading_open}
        case BOSS_LISTS:
            return {...state,boss_lists:action.data}
        case CLEANERS_LISTS:
            return {...state,cleaners_lists:action.data}
    default:
        return state;
    }
}