export const MIDDLE_EARTH_URL =
    process.env.NODE_ENV === 'production'?
        process.env.REACT_APP_ENV !== 'stage'?
        // 'https://bohuaiapi.top:8006':'https://bohuaiapi.top:8006'
        'https://mmll520.com:8006':'https://mmll520.com:8006'
        // 'http://127.0.0.1:3005'
        // :'https://bohuaiapi.top:8006'
        // :'http://124.222.237.211:8005'
        :'https://mmll520.com:8006'
        // :"http://localhost:8000"


export const back_color='#ec7437'