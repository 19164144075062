import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { PlusOutlined } from '@ant-design/icons';
import { Upload, Spin } from 'antd';
import { Input, Space, Tag, theme, Tooltip } from 'antd';
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import { Link ,useLocation} from "react-router-dom";
import { back_color } from '../../configs/constants';
import room_img from '../../static/img/room.png'
import room_show_img from '../../static/img/room_img.png'

import { add_room, open_snackbar_room ,is_loading} from '../../actions/shop'

const _ = require('micro-dash');
const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
};
function Create_room(e) {
    console.log(e)
    const dispatch = useDispatch();
    let shop_id = e.match.params.shop_id
    let shop_name=e.location.state.shop_name
    const [room_imgs, setRoomImg] = useState(false);
    const [RoomimageUrl, setRoomImageUrl] = useState();
    const handleChange_room = (info) => {
        //console.log(info)
        if(info.file.size > 1048576){
            alert('图片大小需在1mb以内')
        }else{
            let img=new Image()
            getBase64(info.file, (url) => {
                img.src=url
                img.onload=()=>{
                    let width=img.width
                    let height=img.height
                    let calculate_width=(width/3*4).toFixed(2)
                    if(calculate_width*0.9<height&&height<calculate_width*1.1){
                        setRoomImageUrl(url);
                        setRoomImg(info.file)
                    }else{
                        alert('图片尺寸不对')
                    }
                }
            });
        }
    };
    const uploadButton = (
        <div>
            <PlusOutlined />
            <div
                style={{
                    marginTop: 8,
                }}
            >
                上传图片
            </div>
        </div>
    );
    const modalImportOk = () => {
        // //console.log(fileList)
    }
    const [room_name, setRoomName] = useState('');
    const [show_price, setShowPrice] = useState('');
    const [package_price, setPackagePrice] = useState('');

    const { token } = theme.useToken();
    const [tags, setTags] = useState([]);
    const [inputVisible, setInputVisible] = useState(false);
    const [inputValue, setInputValue] = useState('');
    const [editInputIndex, setEditInputIndex] = useState(-1);
    const [editInputValue, setEditInputValue] = useState('');
    const inputRef = useRef(null);
    const editInputRef = useRef(null);
    useEffect(() => {
        //console.log('1', inputVisible)
        if (inputVisible) {
            inputRef.current?.focus();
        }
    }, [inputVisible]);
    useEffect(() => {
        editInputRef.current?.focus();
    }, [inputValue]);
    const handleClose = (removedTag) => {
        const newTags = tags.filter((tag) => tag !== removedTag);
        //console.log(newTags);
        setTags(newTags);
    };
    const showInput = () => {
        setInputVisible(true);
    };
    const handleInputChange = (e) => {
        //console.log(e)
        setInputValue(e.target.value);
    };
    const handleInputConfirm = () => {
        //console.log(123)
        if (inputValue && tags.indexOf(inputValue) === -1) {
            setTags([...tags, inputValue]);
        }
        setInputVisible(false);
        setInputValue('');
    };
    const handleEditInputChange = (e) => {
        setEditInputValue(e.target.value);
    };
    const handleEditInputConfirm = () => {
        const newTags = [...tags];
        newTags[editInputIndex] = editInputValue;
        setTags(newTags);
        setEditInputIndex(-1);
        setInputValue('');
    };
    const tagInputStyle = {
        width: 78,

        verticalAlign: 'top',
    };
    const tagPlusStyle = {
        background: token.colorBgContainer,
        borderStyle: 'dashed',
        height: 30,
        paddingTop: 4
    };

    const creation = () => {
        if (room_name === '') {
            alert('店铺名不能为空')
        } else if (tags.length === 0) {
            alert('标题最少需一个')
        } else if (show_price === '' && typeof show_price === 'number') {
            alert('展示价格不能为空且必须是数字')
        } else if (package_price === '' && typeof package_price === 'number') {
            alert('套餐价格不能为空且必须是数字')
        } else if (_.isNil(RoomimageUrl)) {
            alert('房间图片不能为空')
        } else {
            let formData = new FormData()
            let tag = ''
            for (let i = 0; i < tags.length; i++) {
                if (tag === '') {
                    tag = tags[i]
                } else {
                    tag = tag + ',' + tags[i]
                }
            }
            formData.append('file', room_imgs)
            formData.append('room_name', room_name)
            formData.append('status', 0)
            formData.append('price', show_price)
            formData.append('package_price', package_price)
            formData.append('tag', tag)
            formData.append('shop_id', shop_id)
            dispatch(add_room(formData))
            dispatch(is_loading(true))
        }
    }
    const is_create_room = useSelector((state) => state.shop.is_create_room);
    const is_room_open = useSelector((state) => state.shop.is_room_open);
    const [state, setState] = React.useState({
        vertical: 'top',
        horizontal: 'center',
    });
    const { vertical, horizontal } = state;

    const alert_handleClose = () => {
        dispatch(open_snackbar_room(false))
    };
    const loading = useSelector((state) => state.shop.is_loading)
    const handleClick=()=>{}
    return (
        <div >
            <Spin spinning={loading}>
                <div style={{ display: 'flex' }}>
                    <div style={{ width: '50%' }}>
                        <div role="presentation" onClick={handleClick} style={{paddingBottom:'3%'}}>
                            <Breadcrumbs aria-label="breadcrumb">
                                <Link underline="hover" color="inherit"  to= {{ pathname: '/' }} style={{color:'#646060'}}>
                                    首页
                                </Link>
                                <Link
                                underline="hover"
                                color="inherit"
                                to={{pathname: '/shop_show/' +shop_id }}
                                style={{color:'#646060'}}
                                >
                                {shop_name}
                                </Link>
                                <Typography color="text.primary" style={{fontWeight:'bold'}}>创建房间</Typography>
                            </Breadcrumbs>
                        </div>
                        {/* <div style={{ fontSize: 16, fontWeight: 'bold', paddingBottom: 20 }}>创建房间</div> */}
                        <div style={{ display: 'flex' }}>
                            <div style={{ paddingBottom: 20, width: '50%' }}>
                                <div style={{ fontSize: 12, paddingBottom: 8 }}>房间展示图片(比例：3：4)</div>
                                <Upload
                                    {...modalImportOk}
                                    name="avatar"
                                    listType="picture-card"
                                    className="avatar-uploader"
                                    showUploadList={false}
                                    action=""
                                    maxCount='1'
                                    onChange={handleChange_room}
                                    beforeUpload={() => {
                                        //阻止上传
                                        return false;
                                    }}
                                >
                                    {RoomimageUrl ? (
                                        <img
                                            src={RoomimageUrl}
                                            alt="avatar"
                                            style={{
                                                height: '100%',
                                                width: '80%'
                                            }}
                                        />
                                    ) : (
                                        uploadButton
                                    )}
                                </Upload>
                            </div>
                        </div>
                        <div>
                            <div style={{ paddingBottom: 20 }}>
                                <TextField id="outlined-basic" required style={{ width: '40%' }} label="房间名" variant="outlined" value={room_name} onChange={(event) => { setRoomName(event.target.value) }} />
                            </div>
                            <div style={{ fontSize: 13, paddingBottom: 10 }}>
                                创建标签:
                            </div>
                            <div style={{ paddingBottom: 20 }}>
                                <Space size={[0, 8]} wrap>
                                    <Space size={[0, 8]} wrap>
                                        {tags.map((tag, index) => {
                                            if (editInputIndex === index) {
                                                return (
                                                    <Input
                                                        ref={editInputRef}
                                                        key={tag}
                                                        size="default "
                                                        style={tagInputStyle}
                                                        value={editInputValue}
                                                        onChange={handleEditInputChange}
                                                        onBlur={handleEditInputConfirm}
                                                        onPressEnter={handleEditInputConfirm}
                                                    />
                                                );
                                            }
                                            const isLongTag = tag.length > 20;
                                            const tagElem = (
                                                <Tag
                                                    key={tag}
                                                    closable
                                                    style={{
                                                        userSelect: 'none',
                                                        height: 30,
                                                        fontSize: 12,
                                                        paddingTop: 4
                                                    }}
                                                    onClose={() => handleClose(tag)}
                                                >
                                                    <span
                                                        onDoubleClick={(e) => {
                                                            if (index !== 0) {
                                                                setEditInputIndex(index);
                                                                setEditInputValue(tag);
                                                                e.preventDefault();
                                                            }
                                                        }}
                                                    >
                                                        {isLongTag ? `${tag.slice(0, 20)}...` : tag}
                                                    </span>
                                                </Tag>
                                            );
                                            return isLongTag ? (
                                                <Tooltip title={tag} key={tag}>
                                                    {tagElem}
                                                </Tooltip>
                                            ) : (
                                                tagElem
                                            );
                                        })}
                                    </Space>
                                    {inputVisible ? (
                                        <Input
                                            ref={inputRef}
                                            type="text"
                                            size="default "
                                            style={tagInputStyle}
                                            value={inputValue}
                                            onChange={handleInputChange}
                                            onBlur={handleInputConfirm}
                                            onPressEnter={handleInputConfirm}
                                        />
                                    ) : (
                                        <Tag style={tagPlusStyle} onClick={showInput}>
                                            <PlusOutlined /> 添加标签
                                        </Tag>
                                    )}
                                </Space>
                                <div style={{ fontSize: 12, paddingTop: 5, color: 'rgba(0,0,0,0.5)' }}>*可添加多标签，如 独立卫生间，有窗，房间大小等</div>
                            </div>
                            <div style={{ paddingBottom: 20 }}>
                                <TextField id="outlined-basic" required style={{ width: '40%' }} label="展示价格" variant="outlined" value={show_price} onChange={(event) => { setShowPrice(event.target.value) }} />
                                <div style={{ fontSize: 12, paddingTop: 5, color: 'rgba(0,0,0,0.5)' }}>*该价格仅用于展示</div>
                            </div>
                            <div style={{ paddingBottom: 20 }}>
                                <TextField id="outlined-basic" required style={{ width: '40%' }} label="套餐价格" variant="outlined" value={package_price} onChange={(event) => { setPackagePrice(event.target.value) }} />
                                <div style={{ fontSize: 12, paddingTop: 5, color: 'rgba(0,0,0,0.5)' }}>*套餐价格为3小时套餐，3小时后每小时价格为套餐价格/3</div>
                                <div style={{ fontSize: 12, paddingTop: 5, color: 'rgba(0,0,0,0.5)' }}>*套餐价格一般在50-100之间</div>
                            </div>
                            <div>
                                <Button variant="contained" style={{ background: back_color, width: '40%' }} size="large" onClick={creation}>创建</Button>
                            </div>
                        </div>
                    </div>
                    <div style={{ display: 'flex' }}>
                        <div style={{ paddingLeft: 30 }}>
                            <div style={{ fontSize: 12, paddingBottom: 10, textAlign: 'center' }}>房间展示示例图片</div>
                            <div>
                                <img src={room_img} style={{ width: 200, height: 400 }} alt='' />
                            </div>
                        </div>
                        <div style={{ paddingLeft: 30 }}>
                            <div style={{ fontSize: 12, paddingBottom: 10, textAlign: 'center' }}>房间内容示例图片</div>
                            <div>
                                <img src={room_show_img} style={{ width: 200, height: 400 }} alt='' />
                            </div>
                        </div>
                    </div>
                    <Snackbar open={is_room_open} autoHideDuration={3000} onClose={alert_handleClose} anchorOrigin={{ vertical, horizontal }}>
                        <Alert icon={false} onClose={alert_handleClose} severity="success" sx={{ width: '100%' }}>
                            {is_create_room ? '创建成功' : '创建失败'}
                        </Alert>
                    </Snackbar>
                </div>
            </Spin>
        </div>
    )
}

export default Create_room;