import { SHOP_LISTS,LOCATION,SHOP_DATA,ROOM_LISTS,IS_CREATE_ROOM,ROOM_DATA,IS_CREATE_SHOP,IS_SHOP_OPEN,IS_ROOM_OPEN,IS_DETAIL_ROOMS,IS_ROOM_BINDING,IS_DETAIL_SHOPS,
    IS_SHOP_BINDING,IS_LOADING,ONLINE_ERR,IS_ONLINE_SHOP,IS_PRICE,PRICE_STATE,MAP_ERR,HIDE_MAP,ADDRESS_JUDGE,IS_AMEND,AMEND_STATE,IS_ONLINE_ROOM,IS_ONLINE_STATE} from './types';
import { MIDDLE_EARTH_URL } from '../configs/constants';
import axios from 'axios';
const _ = require('micro-dash');
const $http=axios.create();
$http.interceptors.request.use(config => {
    config.headers.Authorization='token ' + localStorage.getItem('token')
    return config
})

export function is_loading(is_loading){
    return {
        type:IS_LOADING,
        is_loading
    }
}
export function shops(message){
    return {
        type:SHOP_LISTS,
        message
    }
}
export function addr_location(location){
    return {
        type:LOCATION,
        location
    }
}
export function shop_data(data){
    return {
        type:SHOP_DATA,
        data
    }
}

export function room_lists(data){
    return {
        type:ROOM_LISTS,
        data
    }
}
export function is_create_room(data){
    return {
        type:IS_CREATE_ROOM,
        data
    }
}
export function room_data(data){
    return {
        type:ROOM_DATA,
        data
    }
}
export function is_create_shop(data){
    return {
        type:IS_CREATE_SHOP,
        data
    }
}
export function open_snackbar_shop(is_open){
    return {
        type:IS_SHOP_OPEN,
        is_open
    }
}
export function open_snackbar_room(is_open){
    return {
        type:IS_ROOM_OPEN,
        is_open
    }
}
export function detail_rooms(is_detail){
    return {
        type:IS_DETAIL_ROOMS,
        is_detail
    }
}
export function room_equipment_binding(is_binding){
    return {
        type:IS_ROOM_BINDING,
        is_binding
    }
}
export function detail_shops(is_detail){
    return {
        type:IS_DETAIL_SHOPS,
        is_detail
    }
}
export function shop_equipment_binding(is_binding){
    return {
        type:IS_SHOP_BINDING,
        is_binding
    }
}
export function online_err(online_err){
    return {
        type:ONLINE_ERR,
        online_err
    }
}
export function is_online(is_online_shop){
    return {
        type:IS_ONLINE_SHOP,
        is_online_shop
    }
}
export function is_revamp_price(is_price){
    return {
        type:IS_PRICE,
        is_price
    }
}
export function price_state(state){
    return {
        type:PRICE_STATE,
        state
    }
}
export function hide_map(start){
    return {
        type:HIDE_MAP,
        start
    }
}
export function is_address_judge(start){
    return {
        type:ADDRESS_JUDGE,
        start
    }
}
export function is_map_err(start){
    return {
        type:MAP_ERR,
        start
    }
}
export function is_revamp_amend(is_amend){
    return {
        type:IS_AMEND,
        is_amend
    }
}
export function is_amend_state(amend_state){
    return {
        type:AMEND_STATE,
        amend_state
    }
}
export function is_online_rooms(is_online_room){
    return {
        type:IS_ONLINE_ROOM,
        is_online_room
    }
}
export function now_online(online_message,is_online_state){
    return {
        type:IS_ONLINE_STATE,
        online_message,
        is_online_state
    }
}

export function shops_lists(){
    return (dispatch, getState)=>{
        $http.get(`${MIDDLE_EARTH_URL}/api/shop/get_shops_by_user/`,
            {
                
            },
            {
                headers:{
                    'content-type': 'application/json', // 默认值
                    'Authorization':'Token '+localStorage.getItem('token')
                }
            }).then(
                response=>{
                    //console.log(response)
                    dispatch(shops(response.data))
                }
            )
    }
}
export function upload_img(data,location_message){
    //console.log(data.getAll('file'))
    return (dispatch, getState)=>{
        axios.post(`${MIDDLE_EARTH_URL}/api/shop/upload_img/`,
            data,
            {
                headers:{
                    // 'Content-Type': 'application/x-www-form-urlencoded', // 默认值
                    'Authorization':'token '+localStorage.getItem('token')
                }
            }).then(
                response=>{
                    //console.log(response)
                    dispatch(open_snackbar_shop(true))
                    dispatch(is_loading(false))
                    if(response.status===200){
                        dispatch(is_create_shop(true))
                    }else{
                        dispatch(is_create_shop(false))
                    }
                }
            ).catch(err=>{
                dispatch(open_snackbar_shop(true))
                dispatch(is_create_shop(false))
                dispatch(is_loading(false))
            })
    }
}
export function shop_location(location){
    return (dispatch,getState)=>{
        //console.log(location)
        axios.post(`${MIDDLE_EARTH_URL}/api/shop/location/`,location,{
            headers:{
                // 'Content-Type': 'application/x-www-form-urlencoded', // 默认值
                'Authorization':'token '+localStorage.getItem('token')
            }
        }).then(response=>{
            dispatch(is_address_judge(null))
            dispatch(is_map_err(false))
            //console.log(response)
        })
    }
}
export function add_shops(data){
    return (dispatch,getState)=>{
        axios.post(`${MIDDLE_EARTH_URL}/api/shop/shops/`,data.data,
        {
            headers:{
                'Authorization':'token '+localStorage.getItem('token')
            }
        }).then(response=>{
            //console.log(response)
            data.location_message.shop_id=response.data.id
            data.img_lists.append('pid',response.data.id)
            dispatch(shop_location(data.location_message))
            dispatch(upload_img(data.img_lists,data.location_message))
            // //console.log(response)
        }).catch(err=>{
            dispatch(open_snackbar_shop(true))
            dispatch(is_create_shop(false))
            dispatch(is_loading(false))
        })
    }
}
export function address(detailed_address){
    return (dispatch,getState)=>{
        axios.post(`${MIDDLE_EARTH_URL}/api/shop/check_address/`,{
            detail_address:detailed_address
        },{
            'content-type': 'application/json',
        }).then(
            response=>{
                //console.log(response)
                dispatch(is_loading(false))
                if(response.data.status===0){
                    dispatch(addr_location(response.data.result.location))
                    dispatch(hide_map(true))
                }else{
                    dispatch(hide_map(true))
                }
            }
        ).catch(err=>{
            dispatch(hide_map(true))
            dispatch(is_loading(false))
            //console.log(err)
        })
    }
}
export function shop_message(shop_id){
    return (dispatch,getState)=>{
        axios.get(`${MIDDLE_EARTH_URL}/api/shop/shop/`+shop_id+'/',{
            headers:{
                'Authorization':'token '+localStorage.getItem('token')
            }
        }).then(response=>{
            //console.log(response)
            dispatch(shop_data(response.data))
            axios.get(`${MIDDLE_EARTH_URL}/api/shop/rooms/?shop_id=`+shop_id,{
                headers:{
                    'Authorization':'token '+localStorage.getItem('token')
                }
            }).then(res=>{
                //console.log(res)
                dispatch(room_lists(res.data))
            })
        })
    }
}
export function add_room(data){
    return (dispatch,getState)=>{
        axios.post(`${MIDDLE_EARTH_URL}/api/shop/rooms/`,data,
        {
            headers:{
                'Authorization':'token '+localStorage.getItem('token')
            }
        }).then(response=>{
            //console.log(response)
            dispatch(open_snackbar_room(true))
            dispatch(is_loading(false))
            if(response.status===200){
                dispatch(is_create_room(true))
            }else{
                dispatch(is_create_room(false))
            }
        }).catch(err=>{
            dispatch(is_loading(false))
            dispatch(open_snackbar_room(true))
            dispatch(is_create_room(false))
        })
    }
}

export function room_message(room_id){
    return (dispatch,getState)=>{
        axios.get(`${MIDDLE_EARTH_URL}/api/shop/room/`+room_id+'/',{
            headers:{
                'Authorization':'token '+localStorage.getItem('token')
            }
        }).then(response=>{
            //console.log(response)
            dispatch(room_data(response.data))
        })
    }
}

export function add_device(room_id,device_code){
    return (dispatch,getState)=>{
        axios.post(`${MIDDLE_EARTH_URL}/api/shop/add_device/`,{
            device_code:device_code,
            room_id:room_id
        },{
            headers:{
                'Authorization':'token '+localStorage.getItem('token')
            }
        }).then(response=>{
            //console.log(response)
            dispatch(open_snackbar_room(true))
            if(response.status===200){
                dispatch(room_equipment_binding(true))
            }else{
                dispatch(room_equipment_binding(false))
            }
        }).catch(err=>{
            dispatch(open_snackbar_room(true))
            dispatch(room_equipment_binding(false))
        })
    }
}

export function detail_room(room_id){
    return (dispatch,getState)=>{
        axios.delete(`${MIDDLE_EARTH_URL}/api/shop/room/`+room_id+'/',{
            headers:{
                'Authorization':'token '+localStorage.getItem('token')
            }
        }).then(response=>{
            //console.log(response)
            if(response.status===204){
                dispatch(detail_rooms(true))
            }
        })
    }
}
export function detail_shop(shop_id){
    return (dispatch,getState)=>{
        axios.delete(`${MIDDLE_EARTH_URL}/api/shop/shop/`+shop_id+'/',{
            headers:{
                'Authorization':'token '+localStorage.getItem('token')
            }
        }).then(response=>{
            //console.log(response)
            if(response.status===204){
                dispatch(detail_shops(true))
            }
        })
    }
}
export function add_shop_device(shop_id,device_code){
    return (dispatch,getState)=>{
        axios.post(`${MIDDLE_EARTH_URL}/api/shop/add_shop_device/`,{
            device_code:device_code,
            shop_id:shop_id
        },{
            headers:{
                'Authorization':'token '+localStorage.getItem('token')
            }
        }).then(response=>{
            //console.log(response)
            dispatch(open_snackbar_shop(true))
            if(response.status===200){
                dispatch(shop_equipment_binding(true))
            }else{
                dispatch(shop_equipment_binding(false))
            }
        }).catch(err=>{
            dispatch(open_snackbar_shop(true))
            //console.log(err)
            dispatch(shop_equipment_binding(false))
        })
    }
}

export function online_shop(shop_id){
    return (dispatch,getState)=>{
        axios.post(`${MIDDLE_EARTH_URL}/api/shop/go_online/`,{
            shop_id:shop_id
        },{
            headers:{
                'Authorization':'token '+localStorage.getItem('token')
            }
        }).then(response=>{
            //console.log(response)
            dispatch(is_online(true))
            if(response.status===200){
                dispatch(online_err('成功上线'))
            }
        }).catch(err=>{
            //console.log(err)
            dispatch(is_online(true))
            dispatch(online_err(err.response.data.err))
        })
    }
}
export function revamp_price(room_id,price,package_price,shop_id){
    return (dispatch,getState)=>{
        axios.put(`${MIDDLE_EARTH_URL}/api/shop/room/`+room_id+'/',{
            price:price,
            package_price:package_price,
            shop_id:shop_id
        },{
            headers:{
                'Authorization':'token '+localStorage.getItem('token')
            }
        }).then(response=>{
            //console.log(response)
            dispatch(is_revamp_price(true))
            if(response.status===200){
                dispatch(price_state(true))
            }else{
                dispatch(price_state(false))
            }
        }).catch(err=>{
            dispatch(is_revamp_price(true))
            dispatch(price_state(false))
        })
    }
}
export function update_room(data){
    return (dispatch,getState)=>{
        axios.patch(`${MIDDLE_EARTH_URL}/api/shop/partial_update_room_by_id/`,data,{
            headers:{
                'Authorization':'token '+localStorage.getItem('token')
            }
        }).then(response=>{
            //console.log(response)
            dispatch(is_revamp_price(true))
            if(response.status===200){
                dispatch(price_state(true))
            }else{
                dispatch(price_state(false))
            }
        }).catch(err=>{
            dispatch(is_revamp_price(true))
            dispatch(price_state(false))
        })
    }
}
// 修改店铺
export function update_shop(data){
    return (dispatch,getState)=>{
        axios.patch(`${MIDDLE_EARTH_URL}/api/shop/partial_update_shop_by_id/`,data,{
            headers:{
                'Authorization':'token '+localStorage.getItem('token')
            }
        }).then(response=>{
            console.log(response)
            dispatch(is_revamp_amend(true))
            if(response.data.code===200){
                dispatch(is_amend_state(true))
            }else{
                dispatch(is_amend_state(false))
            }
            
        }).catch(err=>{
            dispatch(is_revamp_amend(true))
            dispatch(is_amend_state(false))
        })
    }
}
//修改轮播图片
export function amend_other_img(data){
    return (dispatch,getState)=>{
        axios.post(`${MIDDLE_EARTH_URL}/api/shop/update_shop_images_by_shop_id/`,data,{
            headers:{
                'Authorization':'token '+localStorage.getItem('token')
            }
        }).then(res=>{
            console.log(res)
            dispatch(is_revamp_amend(true))
            if(res.data.code===200){
                dispatch(is_amend_state(true))
            }else{
                dispatch(is_amend_state(false))
            }
        }).catch(err=>{
            dispatch(is_revamp_amend(true))
            dispatch(is_amend_state(false))
        })
    }
}

// 修改店铺封面
export function amend_shop_img(data){
    return (dispatch,getState)=>{
        axios.post(`${MIDDLE_EARTH_URL}/api/shop/update_shop_cover_image_by_shop_id/`,data,{
            headers:{
                'Authorization':'token '+localStorage.getItem('token')
            }
        }).then(res=>{
            console.log(res)
            dispatch(is_revamp_amend(true))
            if(res.data.code===200){
                dispatch(is_amend_state(true))
            }else{
                dispatch(is_amend_state(false))
            }
        }).catch(err=>{
            dispatch(is_revamp_amend(true))
            dispatch(is_amend_state(false))
        })
    }
}
// 修改房间封面
export function amend_room_img(data){
    return (dispatch,getState)=>{
        axios.post(`${MIDDLE_EARTH_URL}/api/shop/update_room_cover_image_by_room_id/`,data,{
            headers:{
                'Authorization':'token '+localStorage.getItem('token')
            }
        }).then(res=>{
            console.log(res)
            dispatch(is_revamp_price(true))
            if(res.data.code===200){
                dispatch(price_state(true))
            }else{
                dispatch(price_state(false))
            }
           
        }).catch(err=>{
            dispatch(is_revamp_price(true))
            dispatch(price_state(false))
        })
    }
}
// 房间临时状态
export function room_online(data){
    return (dispatch,getState)=>{
        axios.post(`${MIDDLE_EARTH_URL}/api/shop/set_room_is_online_status/`,data,{
            headers:{
                'Authorization':'token '+localStorage.getItem('token')
            }
        }).then(res=>{
            console.log(res)
            dispatch(is_online_rooms(true))
            if(res.data.code===200){
                if(res.data.data.room_online){
                    dispatch(now_online('房间上线成功',true))
                    dispatch(room_message(data.room_id))
                }else{
                    dispatch(now_online('房间已临时下线',true))
                    dispatch(room_message(data.room_id))
                }
            }
        }).catch(err=>{
            dispatch(is_online_rooms(true))
            dispatch(now_online('操作失败',false))
        })
    }
}
//最小预约时间
export function update_min_hour(data){
    return (dispatch,getState)=>{
        axios.post(`${MIDDLE_EARTH_URL}/api/shop/update_shop_min_hour/`,data,{
            headers:{
                'Authorization':'token '+localStorage.getItem('token')
            }
        }).then(res=>{
            console.log(res)
            dispatch(is_revamp_amend(true))
            if(res.status===200){
                dispatch(is_amend_state(true))
            }else{
                dispatch(is_amend_state(false))
            }
        }).catch(err=>{
            dispatch(is_revamp_amend(true))
            dispatch(is_amend_state(false))
        })
    }
}