import { SHOP_LISTS,FACILITY_DATA,LOADINGN_OPEN,SNACK_OPEN,RELEVANCY,BOSS_LISTS,CLEANERS_LISTS} from './types';
import { MIDDLE_EARTH_URL } from '../configs/constants';
import axios from 'axios';
const _ = require('micro-dash');
const $http=axios.create();
$http.interceptors.request.use(config => {
    config.headers.Authorization='token ' + localStorage.getItem('token')
    return config
})

export function shops(message){
    return {
        type:SHOP_LISTS,
        message
    }
}
export function loading(loading_open){
    return {
        type:LOADINGN_OPEN,
        loading_open
    }
}
export function snack_open(){
    return {
        type:SNACK_OPEN,
        
    }
}
export function relevancy(mess){
    return {
        type:RELEVANCY,
        mess
    }
}
export function boss_lists(data){
    return {
        type:BOSS_LISTS,
        data
    }
}
export function cleaners_lists(data){
    return {
        type:CLEANERS_LISTS,
        data
    }
}

//店铺列表
export function shops_lists(){
    return (dispatch, getState)=>{
        $http.get(`${MIDDLE_EARTH_URL}/api/shop/get_shops_by_user/`,
            {
                
            },
            {
                headers:{
                    'content-type': 'application/json', // 默认值
                    'Authorization':'Token '+localStorage.getItem('token')
                }
            }).then(
                response=>{
                    // //console.log(response)
                    dispatch(all_boss(response.data[0].id))
                    dispatch(all_cleaners(response.data[0].id))
                    dispatch(shops(response.data))
                }
            )
    }
}

export function all_boss(shop_id){
    return (dispatch,getState)=>{
        $http.get(`${MIDDLE_EARTH_URL}/api/shop/get_all_administrators_by_shopid/?shop_id=`+shop_id,
            {
                
            },
            {
                headers:{
                    'content-type': 'application/json', // 默认值
                    'Authorization':'Token '+localStorage.getItem('token')
                }
            }).then(
                response=>{
                    // console.log(response)
                    dispatch(boss_lists(response.data.data))
                }
            )
    }
}

export function all_cleaners(shop_id){
    return (dispatch,getState)=>{
        $http.get(`${MIDDLE_EARTH_URL}/api/shop/get_all_cleaners_by_shopid/?shop_id=`+shop_id,
            {
                
            },
            {
                headers:{
                    'content-type': 'application/json', // 默认值
                    'Authorization':'Token '+localStorage.getItem('token')
                }
            }).then(
                response=>{
                    // console.log(response)
                    dispatch(cleaners_lists(response.data.data))
                }
            )
    }
}

export function link_boss(shop_id,user_id){
    //console.log(data.getAll('file'))
    return (dispatch, getState)=>{
        axios.post(`${MIDDLE_EARTH_URL}/api/shop/link_boss/`,
            {
                shop_id:shop_id,
                user_id:user_id
            },
            {
                headers:{
                    // 'Content-Type': 'application/x-www-form-urlencoded', // 默认值
                    'Authorization':'token '+localStorage.getItem('token')
                }
            }).then(
                response=>{
                    console.log(response)
                    dispatch(relevancy(true))
                    dispatch(loading(false))
                    dispatch(all_boss(shop_id))
                }
            ).catch(err=>{
                console.log(err)
                dispatch(relevancy(false))
                dispatch(loading(false))
            })
    }
}

export function link_cleaner(shop_id,user_id){
    //console.log(data.getAll('file'))
    return (dispatch, getState)=>{
        axios.post(`${MIDDLE_EARTH_URL}/api/shop/link_cleaner/`,
            {
                shop_id:shop_id,
                user_id:user_id
            },
            {
                headers:{
                    // 'Content-Type': 'application/x-www-form-urlencoded', // 默认值
                    'Authorization':'token '+localStorage.getItem('token')
                }
            }).then(
                response=>{
                    console.log(response)
                    dispatch(relevancy(true))
                    dispatch(loading(false))
                    dispatch(all_cleaners(shop_id))
                }
            ).catch(err=>{
                console.log(err)
                dispatch(relevancy(false))
                dispatch(loading(false))
            })
    }
}