import React, { useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { back_color } from '../../configs/constants';
import { useDispatch, useSelector } from 'react-redux';
import { shops_lists, link_cleaner, link_boss, snack_open, loading, all_boss, all_cleaners } from '../../actions/staff';
import personnel_img from '../../static/img/personnel_img.png'
const _ = require('micro-dash');

function createData(name, phone) {
    return { name, phone };
}

const rows = [
    createData('100019', 15236548741),
    createData('100020', 15236548741),
];
function Personnel() {
    const dispatch = useDispatch();
    const token = useSelector((state) => state.user.token);
    useEffect(() => {
        if (_.isNil(token)) {
            return <Redirect to={'/login'} />;
        } else {
            dispatch(shops_lists())
        }
    }, []);
    const [shop, setShop] = React.useState('');
    const shops = useSelector((state) => state.payment_data.shop_lists);
    const handleChange = (event) => {
        //console.log(event)
        setShop(event.target.value);
        dispatch(all_boss(event.target.value))
        dispatch(all_cleaners(event.target.value))
    };
    useEffect(() => {
        //console.log(shops)
        if (shops.length > 0) {
            setShop(shops[0].id);
        }
    }, [dispatch, shops]);
    const [cleaner_id, setCleaner_id] = useState('');
    const [boss_id, setBoss_id] = useState('');

    const clean_link = () => {
        if (cleaner_id !== '') {
            setDialog(0)
            setOpen(true)
        } else {
            alert('清洁工id不能为空')
        }

    }
    const manage_link = () => {
        if (boss_id !== '') {
            setDialog(1)
            setOpen(true)
        } else {
            alert('管理员id不能为空')
        }
    }
    const [dialog_start, setDialog] = useState(0);
    const [open, setOpen] = useState(false);
    const handleClose = () => {
        setOpen(false);
    };
    const affirm = () => {
        setOpen(false);
        dispatch(loading(true))
        if (dialog_start === 0) {
            // console.log(cleaner_id.split(1000))
            let id = cleaner_id.split(1000)[1]
            if (_.isNil(id)) {
                alert('请填写正确id')
            } else {
                dispatch(link_cleaner(shop, id))
            }
        } else {
            let id = boss_id.split(1000)[1]
            if (_.isNil(id)) {
                alert('请填写正确id')
            } else {
                dispatch(link_boss(shop, id))
            }
        }
    }
    const loading_open = useSelector((state) => state.staff.loading_open);
    const is_relevancy = useSelector((state) => state.staff.is_relevancy);
    const snack_opens = useSelector((state) => state.staff.snack_open);

    const [state, setState] = React.useState({
        vertical: 'top',
        horizontal: 'center',
    });
    const { vertical, horizontal } = state;
    const alert_handleClose = () => {
        dispatch(snack_open())
    }

    const boss_lists = useSelector((state) => state.staff.boss_lists);
    const cleaners_lists = useSelector((state) => state.staff.cleaners_lists);
    return (
        <div style={{ display: 'flex', justifyContent: "space-between" }}>
            <div style={{ width: '70%' }}>
                <div style={{ display: 'flex', paddingBottom: 20 }}>
                    {/* <div style={{display:'flex',alignItems:'center',fontSize:14}}>店铺：</div> */}
                    <div>
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">店铺</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={shop}
                                label="Age"
                                onChange={handleChange}
                            >
                                {
                                    shops.map((item, index) =>
                                        <MenuItem value={item.id} key={index}>{item.name}</MenuItem>
                                    )
                                }
                            </Select>
                        </FormControl>
                    </div>
                </div>
                <div style={{ display: 'flex'}}>
                    <div style={{marginRight:'5%'}}>
                        <div style={{textAlign:'center',background:'#fff',padding:10,fontWeight:'bold',fontSize:18}}>店铺管理员</div>
                        <div style={{ padding: 10 ,display:'flex',background:'#fff'}}>
                            <div style={{ marginBottom: 10 }}>
                                <TextField id="outlined-basic" style={{ width: '100%' }} label="关联用户id" variant="outlined" size="small" value={boss_id} onChange={(event) => { setBoss_id(event.target.value) }} />
                            </div>
                            <div style={{paddingLeft:10}}>
                                <Button variant="contained" style={{ background: back_color }} size="medium" onClick={manage_link}>关联</Button>
                            </div>
                        </div>
                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 350 }} aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>店铺管理员</TableCell>
                                        {/* <TableCell align="center">手机号</TableCell> */}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {boss_lists.map((row) => (
                                        <TableRow
                                            key={row.user_id}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell component="th" scope="row">
                                            用户id1000{row.user_id}
                                            </TableCell>
                                            {/* <TableCell align="center">{row.phone}</TableCell> */}
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
                    <div>
                        <div style={{textAlign:'center',background:'#fff',padding:10,fontWeight:'bold',fontSize:18}}>清洁工</div>
                        <div style={{ padding: 10 ,display:'flex',background:'#fff'}}>
                            <div style={{ marginBottom: 10 }}>
                                <TextField id="outlined-basic" style={{ width: '100%' }} label="关联用户id" variant="outlined" size="small" value={cleaner_id} onChange={(event) => { setCleaner_id(event.target.value) }} />
                            </div>
                            <div style={{paddingLeft:10}}>
                                <Button variant="contained" style={{ background: back_color }} size="medium" onClick={clean_link}>关联</Button>
                            </div>
                        </div>
                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 350 }} aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>清洁工</TableCell>
                                        {/* <TableCell align="center">手机号</TableCell> */}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {cleaners_lists.map((row) => (
                                        <TableRow
                                            key={row.user_id}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell component="th" scope="row">
                                            用户id1000{row.user_id}
                                            </TableCell>
                                            <TableCell align="center">{row.phone}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
                    {/* <div style={{ marginRight: '10%' }}>
                        <div style={{ fontWeight: 'bold' }}>清洁工</div>
                        {
                            cleaners_lists.map((item, index) =>
                                <div style={{ padding: '10px 0' }}>用户id1000{item.user_id}</div>
                            )
                        }
                        <div style={{ paddingTop: 10 }}>
                            <div style={{ width: '100%', paddingBottom: 10 }}>
                                <TextField id="outlined-basic" style={{ width: '100%' }} label="关联用户id" variant="outlined" value={cleaner_id} onChange={(event) => { setCleaner_id(event.target.value) }} />
                            </div>
                            <Button variant="contained" style={{ background: back_color }} onClick={clean_link}>关联</Button>
                        </div>
                    </div>
                    <div>
                        <div style={{ fontWeight: 'bold' }}>店铺管理员</div>
                        {
                            boss_lists.map((item, index) =>
                                <div style={{ padding: '10px 0' }}>用户id1000{item.user_id}</div>
                            )
                        }
                        <div style={{ paddingTop: 10 }}>
                            <div style={{ paddingBottom: 10 }}>
                                <TextField id="outlined-basic" style={{ width: '100%' }} label="关联用户id" variant="outlined" value={boss_id} onChange={(event) => { setBoss_id(event.target.value) }} />
                            </div>
                            <Button variant="contained" style={{ background: back_color }} onClick={manage_link}>关联</Button>
                        </div>
                    </div> */}
                </div>
                <Dialog
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        {"关联"}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {dialog_start === 0 ? '您确认把id为' + cleaner_id + '的用户关联为清洁工吗？' : "您确认把id为" + boss_id + '的用户关联为店铺管理员吗？'}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose}>取消</Button>
                        <Button onClick={affirm} autoFocus>
                            确认
                        </Button>
                    </DialogActions>
                </Dialog>
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={loading_open}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
                <Snackbar open={snack_opens} autoHideDuration={3000} onClose={alert_handleClose} anchorOrigin={{ vertical, horizontal }}>
                    <Alert icon={false} onClose={alert_handleClose} severity="success" sx={{ width: '100%' }}>
                        {is_relevancy ? '关联成功' : '关联失败'}
                    </Alert>
                </Snackbar>
            </div>
            <div style={{ width: '30%', textAlign: 'center' }}>
                <img src={personnel_img} style={{ width: '300px', height: 600 }} alt="" />
            </div>
        </div>
    )
}
export default Personnel;