import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import AddIcon from '@mui/icons-material/Add';
import { shops_lists } from '../../actions/shop'
import { Redirect } from "react-router-dom";
import { TMap, MultiMarker } from '@map-component/react-tmap';
import { back_color } from '../../configs/constants';
const _ = require('micro-dash');

function Storefront() {
    const dispatch = useDispatch();
    const shop_lists = useSelector((state) => state.shop.shop_lists);
    const token=useSelector((state) => state.user.token);
    // let center = { lat: 40.041054, lng: 116.272303 };
    // const [zoom, setZoom] = useState(16);
    // const [loc, setLoc] = useState({ ...center });
    // const geometries = [{position:{...loc}}]
    // console.log(shop_lists)
    useEffect(() => {
        if(_.isNil(token)){
            return <Redirect to={'/login'} />;
        }else{
            dispatch(shops_lists())
        }

    }, []);

    return (
        <div style={{ padding: 20 }}>
            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                {
                    shop_lists.map((item, index) =>
                        <Link to={{ pathname: '/shop_show/' + item.id }} key={index}>
                            <div style={{ boxShadow: '0 0 10px rgba(0, 0, 0, 0.16)', padding: 10, marginRight: 20, marginBottom: 20 }}>
                                <div style={{position:'relative'}}>
                                    <img src={item.cover_url} style={{ width: 120, height: 160 }} alt="" />
                                    <div style={{position:'absolute',left:0,width:120,fontSize:15,bottom:4,textAlign:'center',background:back_color,color:'#fff'}}>{item.status===1?'24小时营业中':'筹备中'}</div>
                                </div>
                                <div style={{ fontSize: 12, textAlign: 'center' }}>{item.name}</div>
                            </div>
                        </Link>
                    )
                }
                <Link to='/create_shop'>
                    <div style={{ boxShadow: '0 0 10px rgba(0, 0, 0, 0.16)', padding: 10, marginRight: 20, marginBottom: 20 }}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <AddIcon style={{ width: 120, height: 180 }} />
                        </div>
                    </div>
                </Link>


            </div>
            {/* <div style={{display: "flex", flexDirection:"column"}}>
                <p>Loc: {loc.lat}, {loc.lng}</p>
                <TMap
                    mapKey="VH4BZ-D6EWH-72ED7-WVZBA-MP6R7-ZSF4P" // The applied key
                    zoom={zoom}
                    center={loc} // set the center point coordinates
                    control={{
                        zoom: { position: 'BOTTOM_RIGHT' },
                        scale: false,
                        rotate: false,
                    }}
                    onClick={(evt)=>{
                        // alert(`${evt.latLng.getLat().toFixed(6)}, ${evt.latLng.getLng().toFixed(6)}`);
                        setLoc({lat: evt.latLng.getLat().toFixed(6), lng:evt.latLng.getLng().toFixed(6)})
                    }}
                >
                    <MultiMarker geometries={geometries} />
                </TMap>
            </div> */}
        </div>
    )
}

export default Storefront;