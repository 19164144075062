import {FACILITY_DATA,LOADINGN_OPEN,IS_EQ_OPEN,ERR_MESSAGE
} from '../actions/types';
import { isUndefined } from 'micro-dash';
import { act } from 'react-dom/test-utils';
const _ = require('micro-dash');

const initialState={
    facility_lists:[],
    loading_open:false,
    eq_open:false,
    err_message:true
}

// eslint-disable-next-line import/no-anonymous-default-export
export default function(state=initialState, action) {
    switch(action.type){
        case FACILITY_DATA:
            return {...state,facility_lists:action.data}
        case LOADINGN_OPEN:
            return {...state,loading_open:action.loading}
        case IS_EQ_OPEN:
            return {...state,eq_open:action.open}
        case ERR_MESSAGE:
            return {...state,err_message:action.message}
    default:
        return state;
    }
}