import React, { useEffect, useRef, useState } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from "react-router-dom";
import TextField from '@mui/material/TextField';
import { PlusOutlined } from '@ant-design/icons';
import { Upload } from 'antd';
import { Input, Space, Tag, Tooltip, Divider, theme } from 'antd';
import { back_color } from '../../configs/constants';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import creatHistory from 'history/createHashHistory'  //返回上一页这段代码
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';



import { add_room, room_message, add_device, detail_room, open_snackbar_room, detail_rooms, revamp_price, is_revamp_price, update_room, amend_room_img,room_online, is_online,is_online_rooms } from '../../actions/shop'

const _ = require('micro-dash');
const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
};
function RoomShow(e) {
    const history = creatHistory();//返回上一页这段代码
    const dispatch = useDispatch();
    let room_id = e.match.params.room_id
    // let shop_name=e.location.state.shop_name
    const uploadButton = (
        <div>
            <PlusOutlined />
            <div
                style={{
                    marginTop: 8,
                }}
            >
                暂无图片
            </div>
        </div>
    );
    const modalImportOk = () => {
        // console.log(fileList)
    }
    const room_data = useSelector((state) => state.shop.room_data);
    useEffect(() => {
        dispatch(room_message(room_id))
    }, []);

    const [equipment_code, setEquipment] = useState('');
    const creation = () => {
        // console.log(Number(equipment_code))
        if ((JSON.stringify(equipment_code).length > 10 || JSON.stringify(equipment_code).length === 10) && (typeof Number(equipment_code) === 'number' && Number(equipment_code) % 1 === 0)) {
            dispatch(add_device(room_id, equipment_code))
        } else {
            alert('请填写正确参数')
            setEquipment('')
        }
    }
    const [is_delete, setIsDelete] = useState(false);
    const handleClose = () => {
        setIsDelete(false);
    };
    const is_delete_room = () => {
        setIsDelete(true);
    }
    const delete_rooms = () => {
        dispatch(detail_room(room_id))
    }
    const is_detail_room = useSelector((state) => state.shop.is_detail_room);
    useEffect(() => {
        if (is_detail_room) {
            history.goBack()
            dispatch(detail_rooms(false))
        }
    }, [dispatch, is_detail_room]);
    const [state, setState] = React.useState({
        vertical: 'top',
        horizontal: 'center',
    });
    const { vertical, horizontal } = state;
    const alert_handleClose = () => {
        dispatch(open_snackbar_room(false))
    };
    const is_room_open = useSelector((state) => state.shop.is_room_open);
    const is_room_binding = useSelector((state) => state.shop.is_room_binding);
    const [show_price, setShowPrice] = useState('');
    const [package_price, setPackagePrice] = useState('');
    const [room_name, setRoomName] = useState('');
    useEffect(() => {
        if (!_.isNil(room_data.device_code.code)) {
            setEquipment(room_data.device_code.code)
            setShowPrice(room_data.price)
            setPackagePrice(room_data.package_price)
            setRoomName(room_data.room_name)
            setRoomImageUrl(room_data.cover_url)
            setTags(room_data.tag)
        } else {

        }

    }, [dispatch, room_data])
    const creation_price = () => {
        console.log(tags)
        if (room_name === '') {
            alert('店铺名不能为空')
        } else if (show_price === '' && typeof show_price === 'number') {
            alert('展示价格不能为空且必须是数字')
        } else if (package_price === '' && typeof package_price === 'number') {
            alert('套餐价格不能为空且必须是数字')
        } else {
            let tag = ''
            for (let i = 0; i < tags.length; i++) {
                if (tag === '') {
                    tag = tags[i]
                } else {
                    tag = tag + ',' + tags[i]
                }
            }
            // dispatch(revamp_price(room_id,show_price,package_price,room_data.shop.id))
            dispatch(update_room({ room_id: room_id, package_price: package_price, price: show_price, room_name: room_name ,tag:tag}))
        }
    }
    const is_price = useSelector((state) => state.shop.is_price);
    const price_state = useSelector((state) => state.shop.price_state);
    const price_handleClose = () => {
        dispatch(is_revamp_price(false))
    }
    const handleClick = () => {

    }
    const [room_imgs, setRoomImg] = useState(false);
    const [RoomimageUrl, setRoomImageUrl] = useState();
    const handleChange_room = (info) => {
        //console.log(info)
        if (info.file.size > 1048576) {
            alert('图片大小需在1mb以内')
        } else {
            let img = new Image()
            getBase64(info.file, (url) => {
                img.src = url
                img.onload = () => {
                    let width = img.width
                    let height = img.height
                    let calculate_width = (width / 3 * 4).toFixed(2)
                    if (calculate_width * 0.9 < height && height < calculate_width * 1.1) {
                        setRoomImageUrl(url);
                        setRoomImg(info.file)
                    } else {
                        alert('图片尺寸不对')
                    }
                }
            });
        }
    };
    const updata_room_img = () => {
        console.log(room_data)
        if (!room_imgs) {
            alert('您未更改图片')
        } else {
            console.log(room_imgs)
            let formData = new FormData()
            formData.append('room_id', room_id)
            formData.append('file', room_imgs);
            dispatch(amend_room_img(formData))
        }
    }
    //修改tag
    const [tags, setTags] = useState([]);
    const { token } = theme.useToken();
    const [inputVisible, setInputVisible] = useState(false);
    const [inputValue, setInputValue] = useState('');
    const [editInputIndex, setEditInputIndex] = useState(-1);
    const [editInputValue, setEditInputValue] = useState('');
    const inputRef = useRef(null);
    const editInputRef = useRef(null);
    useEffect(() => {
        //console.log('1', inputVisible)
        if (inputVisible) {
            inputRef.current?.focus();
        }
    }, [inputVisible]);
    useEffect(() => {
        editInputRef.current?.focus();
    }, [inputValue]);
    const handleClose_tag = (removedTag) => {
        const newTags = tags.filter((tag) => tag !== removedTag);
        //console.log(newTags);
        setTags(newTags);
    };
    const showInput = () => {
        setInputVisible(true);
    };
    const handleInputChange = (e) => {
        //console.log(e)
        setInputValue(e.target.value);
    };
    const handleInputConfirm = () => {
        //console.log(123)
        if (inputValue && tags.indexOf(inputValue) === -1) {
            setTags([...tags, inputValue]);
        }
        setInputVisible(false);
        setInputValue('');
    };
    const handleEditInputChange = (e) => {
        setEditInputValue(e.target.value);
    };
    const handleEditInputConfirm = () => {
        const newTags = [...tags];
        newTags[editInputIndex] = editInputValue;
        setTags(newTags);
        setEditInputIndex(-1);
        setInputValue('');
    };
    const tagInputStyle = {
        width: 78,

        verticalAlign: 'top',
    };
    const tagPlusStyle = {
        background: token.colorBgContainer,
        borderStyle: 'dashed',
        height: 30,
        paddingTop: 4
    };
    const online_status=(e)=>{
        if(e){
            dispatch(room_online({room_id:room_id,room_online:0}))
        }else{
            dispatch(room_online({room_id:room_id,room_online:1}))
        }
    }
    const online_handleClose=()=>{
        dispatch(is_online_rooms(false))
    }
    const is_online_room=useSelector((state) => state.shop.is_online_room);
    const is_online_state=useSelector((state) => state.shop.is_online_state);
    const online_message=useSelector((state) => state.shop.online_message);
    return (
        <div style={{ display: 'flex' }}>
            <div style={{ width: '50%' }}>
                <div role="presentation" onClick={handleClick} style={{ paddingBottom: '3%' }}>
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link underline="hover" color="inherit" to={{ pathname: '/' }} style={{ color: '#646060' }}>
                            首页
                        </Link>
                        <Link
                            underline="hover"
                            color="inherit"
                            to={{ pathname: '/shop_show/' + room_data.shop.id }}
                            style={{ color: '#646060' }}
                        >
                            {room_data.shop.name}
                        </Link>
                        <Typography color="text.primary" style={{ fontWeight: 'bold' }}>{room_name}</Typography>
                    </Breadcrumbs>
                </div>
                {/* <div style={{ fontSize: 16, fontWeight: 'bold', paddingBottom: 20 }}>房间信息</div> */}
                <div style={{ display: 'flex' }}>
                    <div style={{ width: '50%' }}>
                        <div style={{ fontSize: 12, paddingBottom: 8 }}>房间展示图片</div>
                        <Upload
                            {...modalImportOk}
                            name="avatar"
                            listType="picture-card"
                            className="avatar-uploader"
                            showUploadList={false}
                            action=""
                            maxCount='1'
                            onChange={handleChange_room}
                            beforeUpload={() => {
                                //阻止上传
                                return false;
                            }}
                        >
                            {RoomimageUrl ? (
                                <img
                                    src={RoomimageUrl}
                                    alt="avatar"
                                    style={{
                                        height: '100%',
                                        width: '80%'

                                    }}
                                />
                            ) : (
                                uploadButton
                            )}
                        </Upload>
                        <div style={{ fontSize: 12, color: 'rgba(0,0,0,0.5)', paddingTop: 5 }}>注：点击图片即可替换店铺展示图片</div>
                        <div style={{ paddingTop: 20 }}>
                            <Button variant="contained" style={{ background: back_color, }} size="large" onClick={updata_room_img}>图片确认更改</Button>
                        </div>
                    </div>
                </div>
                <Divider />
                <div>
                    <div style={{ paddingBottom: 20 }}>
                        <TextField id="outlined-basic" required style={{ width: '40%' }} label="房间名" variant="outlined" value={room_name} onChange={(event) => { setRoomName(event.target.value) }} />
                    </div>
                    <div style={{ fontSize: 13, paddingBottom: 10 }}>
                        创建标签:
                    </div>
                    <div style={{ paddingBottom: 20 }}>
                        <Space size={[0, 8]} wrap>
                            <Space size={[0, 8]} wrap>
                                {tags.map((tag, index) => {
                                    if (editInputIndex === index) {
                                        return (
                                            <Input
                                                ref={editInputRef}
                                                key={tag}
                                                size="default "
                                                style={tagInputStyle}
                                                value={editInputValue}
                                                onChange={handleEditInputChange}
                                                onBlur={handleEditInputConfirm}
                                                onPressEnter={handleEditInputConfirm}
                                            />
                                        );
                                    }
                                    const isLongTag = tag.length > 20;
                                    const tagElem = (
                                        <Tag
                                            key={tag}
                                            closable
                                            style={{
                                                userSelect: 'none',
                                                height: 30,
                                                fontSize: 12,
                                                paddingTop: 4
                                            }}
                                            onClose={() => handleClose_tag(tag)}
                                        >
                                            <span
                                                onDoubleClick={(e) => {
                                                    if (index !== 0) {
                                                        setEditInputIndex(index);
                                                        setEditInputValue(tag);
                                                        e.preventDefault();
                                                    }
                                                }}
                                            >
                                                {isLongTag ? `${tag.slice(0, 20)}...` : tag}
                                            </span>
                                        </Tag>
                                    );
                                    return isLongTag ? (
                                        <Tooltip title={tag} key={tag}>
                                            {tagElem}
                                        </Tooltip>
                                    ) : (
                                        tagElem
                                    );
                                })}
                            </Space>
                            {inputVisible ? (
                                <Input
                                    ref={inputRef}
                                    type="text"
                                    size="default "
                                    style={tagInputStyle}
                                    value={inputValue}
                                    onChange={handleInputChange}
                                    onBlur={handleInputConfirm}
                                    onPressEnter={handleInputConfirm}
                                />
                            ) : (
                                <Tag style={tagPlusStyle} onClick={showInput}>
                                    <PlusOutlined /> 添加标签
                                </Tag>
                            )}
                        </Space>
                    </div>
                    <div style={{ paddingBottom: 20 }}>
                        <TextField id="outlined-basic" required style={{ width: '40%' }} label="展示价格" variant="outlined" value={show_price} onChange={(event) => { setShowPrice(event.target.value) }} />
                        <div style={{ fontSize: 12, paddingTop: 5, color: 'rgba(0,0,0,0.5)' }}>*该价格仅用于展示</div>
                    </div>
                    <div style={{ paddingBottom: 20 }}>
                        <TextField id="outlined-basic" required style={{ width: '40%' }} label="套餐价格" variant="outlined" value={package_price} onChange={(event) => { setPackagePrice(event.target.value) }} />
                        <div style={{ fontSize: 12, paddingTop: 5, color: 'rgba(0,0,0,0.5)' }}>*套餐价格为3小时套餐，3小时后每小时价格为套餐价格/3</div>
                    </div>
                    <div style={{ paddingBottom: 20 }}>
                        <Button variant="contained" style={{ background: back_color, }} size="large" onClick={creation_price}>确认更改</Button>
                    </div>
                    <div style={{ paddingBottom: 10 }}>
                        <div style={{ paddingBottom: 20, fontSize: 15, fontWeight: 'bold' }}>房间设备绑定（必填）</div>
                        <div style={{ display: 'flex' }}>
                            <div style={{ paddingRight: 10 }}>
                                <TextField id="outlined-basic" label="设备参数" required variant="outlined" value={equipment_code} onChange={(event) => { setEquipment(event.target.value) }} />
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <Button variant="contained" style={{ background: back_color, }} size="large" onClick={creation}>绑定</Button>
                            </div>
                        </div>
                        <div style={{ fontSize: 12, paddingTop: 5, color: 'rgba(0,0,0,0.5)' }}>*参数总数需大于8并且为数字</div>
                        <div style={{ padding: '30px 0' }}>
                            <Button variant="contained" style={{ background: back_color, marginRight:10}} size="large" onClick={()=>online_status(room_data.room_online)} >{room_data.room_online?'临时下线':'房间上线'}</Button>
                            <Button variant="contained" style={{ background: back_color, }} size="large" onClick={is_delete_room}>删除该房间</Button>
                        </div>
                    </div>
                </div>
                <Dialog
                    open={is_delete}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        {"确定要删除该房间吗?"}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            房间信息将全部删除，无法恢复
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose}>取消</Button>
                        <Button onClick={delete_rooms} autoFocus>
                            确认
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
            <Snackbar open={is_room_open} autoHideDuration={3000} onClose={alert_handleClose} anchorOrigin={{ vertical, horizontal }}>
                <Alert icon={false} onClose={alert_handleClose} severity="success" sx={{ width: '100%' }}>
                    {is_room_binding ? '绑定成功' : '绑定失败'}
                </Alert>
            </Snackbar>
            <Snackbar open={is_price} autoHideDuration={3000} onClose={price_handleClose} anchorOrigin={{ vertical, horizontal }}>
                <Alert icon={false} onClose={price_handleClose} severity="success" sx={{ width: '100%' }}>
                    {price_state ? '修改成功' : '绑定失败'}
                </Alert>
            </Snackbar>
            <Snackbar open={is_online_room} autoHideDuration={3000} onClose={online_handleClose} anchorOrigin={{ vertical, horizontal }}>
                <Alert icon={false} onClose={online_handleClose} severity="success" sx={{ width: '100%' }}>
                    {is_online_state ? online_message : '操作失败'}
                </Alert>
            </Snackbar>
        </div>
    )
}

export default RoomShow;