import { DATA_LISTS,SHOP_LISTS,FLOW_DATA,ACTIVITY_LISTS,CREATION_ACTIVITY_ERR,SHOW_CONSEQUENCE,COUPONS_LISTS} from './types';
import { MIDDLE_EARTH_URL } from '../configs/constants';
import axios from 'axios';
const _ = require('micro-dash');
const $http=axios.create();
$http.interceptors.request.use(config => {
    config.headers.Authorization='token ' + localStorage.getItem('token')
    return config
})

export function data_lists(results,page,count){
    return {
        type:DATA_LISTS,
        results,
        page,
        count
    }
}
export function shops(message){
    return {
        type:SHOP_LISTS,
        message
    }
}
export function flow_data(data){
    return {
        type:FLOW_DATA,
        data
    }
}
export function activity_lists(data){
    return {
        type:ACTIVITY_LISTS,
        data
    }
}
export function creation_activity_err(err){
    return {
        type:CREATION_ACTIVITY_ERR,
        err
    }
}
export function show_consequence(is_consequence){
    return {
        type:SHOW_CONSEQUENCE,
        is_consequence
    }
}
export function coupons_lists(data){
    return {
        type:COUPONS_LISTS,
        data
    }
}

function time(time){
    let date=new Date(time)
    let year = date.getFullYear()
    let month = date.getMonth() + 1
    let day = date.getDate()
    let h = date.getHours();
    let m = date.getMinutes();
    return year + "-" + month + "-" + day + " "+h+':'+m
}

export function payment_data(page,shop_id){
    return (dispatch, getState)=>{
        axios.get(`${MIDDLE_EARTH_URL}/api/payment/charge_records_by_shoip/?page=`+page+'&shop_id='+shop_id,
            {
                
            },
            {
                headers:{
                    'content-type': 'application/json', // 默认值
                    'Authorization':'token '+localStorage.getItem('token')
                }
            }).then(
                response=>{
                    // console.log(response)
                    for(let i=0;i<response.data.results.length;i++){
                        let create_time=time(response.data.results[i].create_time)
                        response.data.results[i].create_time=create_time
                    }
                    
                    dispatch(data_lists(response.data.results,page,response.data.count))
                }
            )
    }
}
export function shops_lists(){
    return (dispatch, getState)=>{
        $http.get(`${MIDDLE_EARTH_URL}/api/shop/get_shops_by_user/`,
            {
                
            },
            {
                headers:{
                    'content-type': 'application/json', // 默认值
                    'Authorization':'Token '+localStorage.getItem('token')
                }
            }).then(
                response=>{
                    // console.log(response)
                    dispatch(payment_data(1,response.data[0].id))
                    dispatch(payment_summary(response.data[0].id))
                    dispatch(shops(response.data))
                }
            )
    }
}

export function payment_summary(shop_id){
    return (dispatch,getState)=>{
        $http.get(`${MIDDLE_EARTH_URL}/api/payment/get_shop_payment_summary/?shop_id=`+shop_id,{
            shop_id:shop_id
        },{
            headers:{
                'content-type': 'application/json', // 默认值
                'Authorization':'Token '+localStorage.getItem('token')
            }
        }).then(response=>{
            // console.log(response)
            dispatch(flow_data(response.data.data.flow_data))
        })
    }
}
//创建充值活动
export function create_recharge_activity(data){
    return (dispatch,getState)=>{
        axios.post(`${MIDDLE_EARTH_URL}/api/shop_coupon/create_shop_recharge_activity/`,data,{
            headers:{
                'content-type': 'application/json', // 默认值
                'Authorization':'Token '+localStorage.getItem('token')
            } 
        }).then(response=>{
            console.log(response)
            dispatch(show_consequence(true))
            if(response.data.code===200){
                dispatch(creation_activity_err('创建成功'))
                dispatch(get_recharge_activity(data.shop_id))
            }else{
                dispatch(creation_activity_err(response.data.msg))
            }
        })
    }
}

//查询充值活动
export function get_recharge_activity(shop_id){
    return (dispatch,getState)=>{
        $http.get(`${MIDDLE_EARTH_URL}/api/shop_coupon/get_shop_recharge_activity/?shop_id=`+shop_id,{
            
        },{
            headers:{
                'content-type': 'application/json', // 默认值
                'Authorization':'Token '+localStorage.getItem('token')
            }
        }).then(response=>{
            console.log(response)
            dispatch(activity_lists(response.data.data))
        })
    }
}
//更新充值活动
export function update_recharge_activity(data){
    return (dispatch,getState)=>{
        axios.patch(`${MIDDLE_EARTH_URL}/api/shop_coupon/update_shop_recharge_activity/`,data,{
            headers:{
                'content-type': 'application/json', // 默认值
                'Authorization':'Token '+localStorage.getItem('token')
            }
        }).then(response=>{
            console.log(response)
            dispatch(show_consequence(true))
            if(response.status===200){
                dispatch(creation_activity_err('修改成功'))
                // dispatch(activity_lists([]))
                dispatch(get_recharge_activity(data.shop_id))
            }else{
                dispatch(creation_activity_err(response.data.msg))
            }
        })
    }
}

//删除充值活动
export function delete_recharge_activity(shop_id,recharge_amount){
    return (dispatch,getState)=>{
        $http.delete(`${MIDDLE_EARTH_URL}/api/shop_coupon/delete_shop_recharge_activity/`,{
            data:{
                shop_id:shop_id,
                recharge_amount:recharge_amount
            }
        },{
            headers:{
                'content-type': 'application/json', // 默认值
                'Authorization':'Token '+localStorage.getItem('token')
            }
        }).then(response=>{
            console.log(response)
            dispatch(show_consequence(true))
            if(response.data.code===200){
                dispatch(creation_activity_err('删除成功'))
                dispatch(get_recharge_activity(shop_id))
            }else{
                dispatch(creation_activity_err(response.data.msg))
            }
        })
    }
}

// 获取店铺优惠券
export function get_shop_coupons(shop_id){
    return (dispatch,getState)=>{
        $http.get(`${MIDDLE_EARTH_URL}/api/shop_coupon/get_shop_coupons_by_shop_id/?shop_id=`+shop_id,{
            
        },{
            headers:{
                'content-type': 'application/json', // 默认值
                'Authorization':'Token '+localStorage.getItem('token')
            }
        }).then(response=>{
            console.log(response)
            dispatch(coupons_lists(response.data.data))
        })
    }
}