import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import AddIcon from '@mui/icons-material/Add';
import { Redirect } from "react-router-dom";
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { back_color } from '../../configs/constants';
import { shops_lists, get_recharge_activity, create_recharge_activity, delete_recharge_activity, show_consequence, update_recharge_activity, get_shop_coupons } from '../../actions/payment_data'
const _ = require('micro-dash');

function Marketing() {
    const dispatch = useDispatch();
    const token = useSelector((state) => state.user.token);
    const shops = useSelector((state) => state.payment_data.shop_lists);
    useEffect(() => {
        if (_.isNil(token)) {
            return <Redirect to={'/login'} />;
        } else {
            dispatch(shops_lists())
        }
    }, []);
    const [shop, setShop] = React.useState('');
    useEffect(() => {
        if (shops.length > 0) {
            setShop(shops[0].id);
            dispatch(get_recharge_activity(shops[0].id))
            dispatch(get_shop_coupons(shops[0].id))
        }
    }, [dispatch, shops]);
    const handleChange = (event) => {
        setShop(event.target.value);
        dispatch(get_recharge_activity(event.target.value))
        dispatch(get_shop_coupons(event.target.value))
    };
    const activity_lists = useSelector((state) => state.payment_data.activity_lists);
    const [pay_value, setPay] = React.useState('');
    const [present_value, setPresent] = React.useState('');
    const count = 10
    const page_change = () => { }
    const isInteger=(obj)=> {
        return obj%1 === 0
    }
    const confirm = () => {
        let pay = parseFloat(pay_value)
        let present = parseFloat(present_value)
        console.log(isInteger(pay))
        if(activity_lists.length>4){
            alert('最多支持创建5个活动')
        }else if (isInteger(pay)===false) {
            alert('请输入正确充值金额,金额仅支持整数类型')
        } else if (!isInteger(present)) {
            alert('请输入正确赠送金额,金额仅支持整数类型')
        } else {
            let now_coupon_discount=coupon_discount
            if(now_coupon_discount===0){
                now_coupon_discount=null
            }
            dispatch(create_recharge_activity({ activity_name: '充' + pay + '得' + (pay + present), recharge_amount: pay, gift_amount: present, shop_id: shop, gift_coupon: now_coupon_discount }))
        }
    }
    const [open, setOpen] = React.useState(false);
    const handleClose = () => {
        setOpen(false)
    }
    const [shop_name, setShopName] = React.useState('');
    const [activity_name, setActivityName] = React.useState('');
    const [shop_id, setShop_id] = React.useState('');
    const [recharge_amount, setRecharge_amount] = React.useState('');
    const is_consequence = useSelector((state) => state.payment_data.is_consequence);
    const err_value = useSelector((state) => state.payment_data.err_value);
    const delete_activity = (shop_id, shop_name, name, recharge_amount) => {
        setShop_id(shop_id)
        setShopName(shop_name)
        setActivityName(name)
        setRecharge_amount(recharge_amount)
        setOpen(true)
    }
    const affirm = () => {
        setOpen(false)
        dispatch(delete_recharge_activity(shop_id, recharge_amount))
    }
    const [state, setState] = React.useState({
        vertical: 'top',
        horizontal: 'center',
    });
    const { vertical, horizontal } = state;
    const alert_handleClose = () => {
        dispatch(show_consequence(false))
    }
    const amend_activity = (index) => {
        console.log(activity_lists[index])
        setdata(index)
        setUpdata(true)
        if(activity_lists[index].gift_amount===null){
            setupdataValue(0)
        }else{
            setupdataValue(activity_lists[index].gift_amount)
        }
        setupdata_Discount(activity_lists[index].gift_coupon.id)
    }
    const [updata_value, setupdataValue] = React.useState('');
    const [updata_index, setdata] = React.useState(0);
    const [updata_open, setUpdata] = React.useState(false);
    const updata_handleClose = () => {
        setUpdata(false)
    }
    const updata_affirm = () => {
        console.log(activity_lists[updata_index])
        let present = parseFloat(updata_value)
        let pay = parseFloat(activity_lists[updata_index].recharge_amount)
        if (!isInteger(present)) {
            alert('请输入正确赠送金额,金额仅支持整数类型')
        } else {
            let now_updata_coupon_discount=updata_coupon_discount
            if(now_updata_coupon_discount===0){
                now_updata_coupon_discount=null
            }
            dispatch(update_recharge_activity({ activity_name: '充' + pay + '得' + (pay + present), recharge_amount: pay, gift_amount: present, shop_id: activity_lists[updata_index].shop.id, gift_coupon: now_updata_coupon_discount }))
            setUpdata(false)
        }
    }
    const [coupon_discount, setDiscount] = React.useState(0);
    const discountChange = (event) => {
        setDiscount(event.target.value);
    };
    const [updata_coupon_discount, setupdata_Discount] = React.useState(0);
    const updata_discountChange = (event) => {
        setupdata_Discount(event.target.value);
    };
    const coupons_lists = useSelector((state) => state.payment_data.coupons_lists);
    useEffect(() => {
        if (coupons_lists.length > 0) {
            setDiscount(coupons_lists[0].id)
        }
    }, [dispatch, coupons_lists]);
    return (
        <div style={{ padding: 20 }}>
            <div style={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center' }}>
                <div>
                    <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">店铺</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={shop}
                            label="Age"
                            onChange={handleChange}
                        >
                            {
                                shops.map((item, index) =>
                                    <MenuItem value={item.id} key={index}>{item.name}</MenuItem>
                                )
                            }
                        </Select>
                    </FormControl>
                </div>
                <div style={{ marginLeft: '5%' }}>
                    <TextField id="outlined-basic" label="请输入充值金额" variant="outlined" value={pay_value} onChange={(event) => { setPay(event.target.value) }} />
                </div>
                <div style={{ marginLeft: '5%' }}>
                    <TextField id="outlined-basic" label="请输入赠送金额" variant="outlined" value={present_value} onChange={(event) => { setPresent(event.target.value) }} />
                </div>
                <div style={{ marginLeft: '5%' }}>
                    <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">折扣券</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={coupon_discount}
                            label="Age"
                            onChange={discountChange}
                        >
                            {
                                coupons_lists.map((item, index) =>
                                    <MenuItem value={item.id} >{item.name}</MenuItem>
                                )
                            }
                            <MenuItem value={0} >不送</MenuItem>
                        </Select>
                    </FormControl>
                </div>
                <div style={{ height: '100%', marginLeft: '5%' }}>
                    <Button variant="contained" size="large" style={{ background: back_color }} onClick={confirm}>创建</Button>
                </div>
            </div>
            <div style={{ paddingTop: 10 }}>
                <TableContainer component={Paper} style={{ height: '70vh' }}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>店铺名</TableCell>
                                <TableCell align="center">活动名</TableCell>
                                <TableCell align="center">充值金额</TableCell>
                                <TableCell align="center">赠送金额</TableCell>
                                <TableCell align="center">赠送折扣券</TableCell>
                                <TableCell align="center">编辑</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {activity_lists.map((row, index) => (
                                <TableRow
                                    key={index}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell component="th" scope="row">
                                        {row.shop.name}
                                    </TableCell>
                                    <TableCell align="center">{row.activity_name}</TableCell>
                                    <TableCell align="center">￥{row.recharge_amount}</TableCell>
                                    <TableCell align="center">￥{row.gift_amount}</TableCell>
                                    <TableCell align="center">
                                        {
                                            row.gift_coupon!= null ? row.gift_coupon.discount * 10 + '折' : '无'
                                        }
                                    </TableCell>
                                    <TableCell align="center">
                                        <div>
                                            <Button variant="contained" style={{ background: back_color, marginRight: 8 }} onClick={() => amend_activity(index)}>修改</Button>
                                            <Button variant="contained" style={{ background: back_color }} onClick={() => delete_activity(row.shop.id, row.shop.name, row.activity_name, row.recharge_amount)}>删除</Button>
                                        </div>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <div style={{ display: 'flex', justifyContent: 'end', fontSize: 13, color: 'rgba(0,0,0,0.8)', alignItems: 'center' }}>
                    <div style={{ paddingRight: 8, paddingTop: 5 }}>
                        <InfoOutlinedIcon />
                    </div>
                    <div>说明：每个店铺最多创建5个营销活动,修改仅支持修改赠送金额与折扣券;金额需设置整数，不支持小数</div>
                </div>
                {/* <div style={{ paddingTop: 5, display: 'flex', justifyContent: 'center' }}>
                    <Stack spacing={2}>
                        <Pagination count={Math.ceil(count / 5)} onChange={page_change} />
                    </Stack>
                </div> */}
            </div>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"删除"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        您确认要删除 {shop_name} 的 {activity_name}的活动吗？
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>取消</Button>
                    <Button onClick={affirm} autoFocus>
                        确认
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={updata_open}
                onClose={updata_handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                {/* <DialogTitle id="alert-dialog-title" style={{fontSize:14}}>
                    {'修改'+activity_lists[updata_index].shop.name+' '+activity_lists[updata_index].activity_name+'活动'}
                </DialogTitle> */}
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <div style={{ display: 'flex' }}>
                            <div style={{ width: '50%', paddingRight: '5%' }}>
                                <TextField id="standard-basic" label="赠送金额" variant="standard" value={updata_value} onChange={(event) => { setupdataValue(event.target.value) }} />
                            </div>
                            <div style={{ width: '40%' }}>
                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">折扣券</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={updata_coupon_discount}
                                        label="Age"
                                        onChange={updata_discountChange}
                                    >
                                        {
                                            coupons_lists.map((item, index) =>
                                                <MenuItem value={item.id} >{item.name}</MenuItem>
                                            )
                                        }
                                        <MenuItem value={0} >不送</MenuItem>
                                    </Select>
                                </FormControl>
                            </div>
                        </div>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={updata_handleClose}>取消</Button>
                    <Button onClick={updata_affirm} autoFocus>
                        确认
                    </Button>
                </DialogActions>
            </Dialog>
            <Snackbar open={is_consequence} autoHideDuration={3000} onClose={alert_handleClose} anchorOrigin={{ vertical, horizontal }}>
                <Alert icon={false} onClose={alert_handleClose} severity="success" sx={{ width: '100%' }}>
                    {err_value}
                </Alert>
            </Snackbar>
        </div>
    )
}

export default Marketing;