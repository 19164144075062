import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from "react-router-dom";
import TextField from '@mui/material/TextField';
import AddIcon from '@mui/icons-material/Add';
import { PlusOutlined } from '@ant-design/icons';
import { Upload, Modal, Divider } from 'antd';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { back_color } from '../../configs/constants';
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import creatHistory from 'history/createHashHistory'  //返回上一页这段代码
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'


import { shop_message, detail_shop, detail_shops, add_shop_device, open_snackbar_shop, online_shop, is_online, update_shop, is_revamp_amend, amend_other_img, amend_shop_img,update_min_hour } from '../../actions/shop'

const _ = require('micro-dash');
const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
};
const getItems = count => (
    Array.from({ length: count }, (v, k) => k).map(k => ({
      id: `item-${k + 1}`,
      content: `this is content ${k + 1}`
    }))
  )
  
  // 重新记录数组顺序
  const reorder = (list, startIndex, endIndex) => {
    console.log(list)
    const result = Array.from(list);
    //删除并记录 删除元素
    const [removed] = result.splice(startIndex, 1);
    //将原来的元素添加进数组
    result.splice(endIndex, 0, removed);
    return result;
  };
  
  const grid = 8;
  
  
  // 设置样式
  const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    // userSelect: "none",
    // padding: grid * 2,
    // margin: `0 ${grid}px 0 0 `,
    display:'inline-block',
    // // 拖拽的时候背景变化
    // background: isDragging ? "lightgreen" : "#ffffff",
  
    // styles we need to apply on draggables
    ...draggableStyle
  });
  
  const getListStyle = () => ({
    // background: 'black',
    display: 'flex',
    flexWrap: 'wrap',
    
    padding: grid,
    overflow: 'auto', 
  });
function ShopShow(e) {
    const history = creatHistory();//返回上一页这段代码
    let shop_id = e.match.params.shop_id
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(shop_message(shop_id))
    }, [shop_id])
    const shop_data = useSelector((state) => state.shop.shop_data);
    const room_lists = useSelector((state) => state.shop.room_lists);
    //console.log(room_lists)
    //店铺封面图片
    const [shop_img, setShopImg] = useState(false);
    const [imageUrl, setImageUrl] = useState();
    const handleChange = (info) => {
        //console.log(info)
        getBase64(info.file, (url) => {
            setImageUrl(url);
            setShopImg(info.file)
        });
    };
    const uploadButton = (
        <div>
            <PlusOutlined />
            <div
                style={{
                    marginTop: 8,
                }}
            >
                添加图片
            </div>
        </div>
    );
    // 房间展示图片

    //宣传图片
    const [previewOpen, setPreviewOpen] = useState(false);
    const [previewImage, setPreviewImage] = useState('');
    const [previewTitle, setPreviewTitle] = useState('');
    const [fileList, setFileList] = useState([]);
    const handleCancel = () => setPreviewOpen(false);
    // const handleChanges = ({ fileList: newFileList }) => setFileList(newFileList);
    const handleChanges = ({ fileList: newFileList }) => {
        console.log(newFileList)
        if (newFileList[newFileList.length - 1].size > 1048576) {
            alert('图片大小需在1mb以内')
        } else {
            let img = new Image()
            if (!_.isNil(newFileList[newFileList.length - 1].originFileObj)) {
                getBase64(newFileList[newFileList.length - 1].originFileObj, (url) => {
                    img.src = url
                    img.onload = () => {
                        let width = img.width
                        let height = img.height
                        let calculate_width = (width / 5 * 3).toFixed(2)
                        if (calculate_width * 0.9 < height && height < calculate_width * 1.1) {
                            setFileList(newFileList)
                        } else {
                            alert('图片尺寸不对')
                        }
                    }
                });
            } else {
                setFileList(newFileList)
            }
        }

    };


    const modalImportOk = () => {
        //console.log(fileList)
    }
    const [equipment_code, setEquipment] = useState('');
    const creation = () => {
        if ((JSON.stringify(equipment_code).length > 10 || JSON.stringify(equipment_code).length === 10) && equipment_code % 1 === 0) {
            dispatch(add_shop_device(shop_id, equipment_code))
        } else {
            alert('请填写正确参数')
            setEquipment('')
        }
    }

    const [is_delete, setIsDelete] = useState(false);
    const handleClose = () => {
        setIsDelete(false);
    };
    const is_delete_shop = () => {
        setIsDelete(true);
    }
    const delete_shop = () => {
        dispatch(detail_shop(shop_id))
    }
    const is_detail_shop = useSelector((state) => state.shop.is_detail_shop);
    useEffect(() => {
        if (is_detail_shop) {
            history.goBack()
            dispatch(detail_shops(false))
        }
    }, [dispatch, is_detail_shop]);
    const [state, setState] = React.useState({
        vertical: 'top',
        horizontal: 'center',
    });
    const { vertical, horizontal } = state;
    const alert_handleClose = () => {
        dispatch(open_snackbar_shop(false))
    };
    const is_shop_open = useSelector((state) => state.shop.is_shop_open);
    const is_shop_binding = useSelector((state) => state.shop.is_shop_binding);
    //console.log('shop_data',shop_data)
    useEffect(() => {
        setEquipment(shop_data.device_code)
        setShopname(shop_data.name)
        setArea(shop_data.area)
        setNum(shop_data.capacity)
        setPhone(shop_data.phone_number)
        setAddress(shop_data.addr)
        setImageUrl(shop_data.cover_url)
        setFileList(shop_data.other_img)
        setMinhour(shop_data.min_hour)
    }, [dispatch, shop_data])
    const go_online = () => {
        dispatch(online_shop(shop_id))
    }
    const is_online_shop = useSelector((state) => state.shop.is_online_shop);
    const online_err = useSelector((state) => state.shop.online_err);
    const online_handleClose = () => {
        dispatch(is_online(false))
    }
    const [shop_name, setShopname] = useState('');
    // const [shop_site,setSite]=useState('');
    const [shop_area, setArea] = useState('');
    const [shop_num, setNum] = useState('');
    const [shop_phone, setPhone] = useState('');
    const [address_value, setAddress] = useState('');

    const is_amend = useSelector((state) => state.shop.is_amend);
    const amend_state = useSelector((state) => state.shop.amend_state);
    const amend_handleClose = () => {
        dispatch(is_revamp_amend(false))
    }
    const updata_shop = () => {
        if (shop_name === '') {
            alert('店铺名不能为空')
        } else if (address_value === '') {
            alert('地址不能为空')
        } else if ((shop_area > 1000 || shop_area < 10) && (typeof shop_area === 'number' && shop_area % 1 === 0)) {
            alert('面积范围10m²-1000m²且为整数')
        } else if ((shop_num > 100 || shop_num < 1) && (typeof shop_num === 'number' && shop_num % 1 === 0)) {
            alert('容纳人数需在1-100人内')
        } else if (shop_phone.length < 5) {
            alert('请输入正确的手机号')
        } else {
            dispatch(update_shop({ shop_id: shop_data.id, name: shop_name, area: shop_area, capacity: shop_num, phone_number: shop_phone, addr: address_value }))
        }
    }
    const handleClick = (event) => {
        event.preventDefault();
        console.info('You clicked a breadcrumb.');
    }
    const updata_shop_other_img = () => {
        console.log(fileList)
        if (fileList.length === 0) {

        } else {
            let formDatas = new FormData()
            formDatas.append('shop_id', shop_data.id)
            let file = []
            let protect_list = ''
            fileList.forEach((item, index) => {
                //console.log(item)
                if (!_.isNil(item.originFileObj)) {
                    console.log(1)
                    // file.push(item.originFileObj)
                    formDatas.append('file' + index, item.originFileObj);
                } else {
                    if (protect_list !== '') {
                        protect_list = protect_list + ',' + item.url
                    } else {
                        protect_list = protect_list + item.url
                    }

                    // formDatas.append('protect_list', item.url);
                }
            })
            // formDatas.append('file', file);
            formDatas.append('protect_list', protect_list);
            console.log(formDatas)
            dispatch(amend_other_img(formDatas))
        }

    }
    const updata_shop_img = () => {
        if (!shop_img) {
            alert('您未更改图片')
        } else {
            console.log(shop_img)
            let formData = new FormData()
            formData.append('shop_id', shop_data.id)
            formData.append('file', shop_img);
            dispatch(amend_shop_img(formData))
        }
    }

    const [room_sort,setRoomSort]=useState([]);
    useEffect(() => {
        setRoomSort(room_lists)
    }, [dispatch,room_lists])
    const [state1,setstate]=useState('');
    const [state2,setstate2]=useState([]);
    const constructor=(props)=> {
        // super(props);
        setstate({items: getItems(10)})
        
        onDragEnd = onDragEnd.bind(this);
      }
    
      const onDragEnd=(result)=> {
        console.log(result)
        if (!result.destination) {
          return;
        }
        console.log(room_sort)
        const items = reorder(
            room_sort,
            result.source.index,
            result.destination.index
        );
        console.log(items)
        setRoomSort(items)
        // this.setState({
        //   items
        // });
      }
      const [min_hour,setMinhour]=useState(0);
      const creation_hour=()=>{
        let regex=new RegExp('^[1-9]\\d*$')
        if(min_hour===''||!regex.test(min_hour)||min_hour>6){
            alert('最小预约时间需设置整数')
        }else{
            console.log(111)
            dispatch(update_min_hour({shop_id:shop_data.id,min_hour:parseInt(min_hour)}))
        }
      }
    return (
        <div style={{ padding: 20, textAlign: 'left' }}>
            <div role="presentation" onClick={handleClick} style={{ paddingBottom: '3%' }}>
                <Breadcrumbs aria-label="breadcrumb">
                    <Link underline="hover" color="inherit" to={{ pathname: '/' }} style={{ color: '#646060' }}>
                        首页
                    </Link>
                    {/* <Link
                    underline="hover"
                    color="inherit"
                    href="/material-ui/getting-started/installation/"
                    >
                    Core
                    </Link> */}
                    <Typography color="text.primary" style={{ fontWeight: 'bold' }}>{shop_name}</Typography>
                </Breadcrumbs>
            </div>
            {/* <div style={{paddingBottom:'3%',fontSize:15,fontWeight:'bold'}}>店铺</div> */}
            <div>
                <div style={{ marginBottom: 20, display: 'flex', justifyContent: 'flex-start' }}>
                    <div>
                        <div>
                            <div style={{ fontSize: 12, paddingBottom: 8 }}>店铺展示图片</div>
                            <Upload
                                {...modalImportOk}
                                name="avatar"
                                listType="picture-card"
                                className="avatar-uploader"
                                showUploadList={false}
                                action=""
                                maxCount='1'
                                onChange={(info) => { handleChange(info) }}
                                beforeUpload={() => {
                                    //阻止上传
                                    return false;
                                }}
                            >
                                {imageUrl ? (
                                    <img
                                        src={imageUrl}
                                        alt="avatar"
                                        style={{
                                            // width: '65%'
                                            height: '100%',
                                            width: '80%'
                                        }}
                                    />
                                ) : (
                                    uploadButton
                                )}
                            </Upload>
                            <div style={{ fontSize: 12, color: 'rgba(0,0,0,0.5)', paddingTop: 5 }}>注：点击图片即可替换店铺展示图片</div>
                            <div style={{ paddingTop: 20 }}>
                                <Button variant="contained" style={{ background: back_color, }} size="large" onClick={updata_shop_img}>展示图片确认更改</Button>
                            </div>
                        </div>
                        <Divider />
                        {/* <div>
                            <div style={{ fontSize: 12, paddingBottom: 8 }}>店铺介绍图片</div>
                            <Upload
                                {...modalImportOk}
                                listType="picture-card"
                                fileList={shop_data.other_img}
                                beforeUpload={() => {
                                    //阻止上传
                                    return false;
                                }}
                                disabled
                                onChange={(info) => { handleChanges(info) }}
                                action=''
                            >
                                {shop_data.other_img.length >= 3 ? null : ''}
                            </Upload>
                            <Modal open={previewOpen} title={previewTitle} footer={null} onCancel={handleCancel}>
                                <img
                                    alt="example"
                                    style={{
                                        width: '100%',
                                    }}
                                    src={previewImage}
                                />
                            </Modal>
                        </div> */}
                        <div>
                            <div style={{ fontSize: 12, paddingBottom: 8 }}>店铺介绍图片(比例：5：3，多张)</div>
                            <Upload
                                {...modalImportOk}
                                listType="picture-card"
                                fileList={fileList}
                                beforeUpload={() => {
                                    //阻止上传
                                    return false;
                                }}
                                onChange={(info) => { handleChanges(info) }}
                                action=''
                            >
                                {fileList.length >= 5 ? null : uploadButton}
                            </Upload>
                            <Modal open={previewOpen} title={previewTitle} footer={null} onCancel={handleCancel}>
                                <img
                                    alt="example"
                                    style={{
                                        width: '80%',
                                        height: '100%'
                                    }}
                                    src={previewImage}
                                />
                            </Modal>
                        </div>
                        <div style={{ paddingTop: 20 }}>
                            <Button variant="contained" style={{ background: back_color, }} size="large" onClick={updata_shop_other_img}>介绍图片确认更改</Button>
                        </div>
                    </div>
                </div>
                <Divider />
                <div style={{ width: '40%' }}>
                    <div style={{ paddingBottom: 20 }}>
                        <TextField id="outlined-basic" label="店铺名" variant="outlined" value={shop_name} onChange={(event) => { setShopname(event.target.value) }} />
                    </div>
                    <div style={{ paddingBottom: 20, }}>
                        <TextField id="outlined-basic" label="详细地址" variant="outlined" value={address_value} onChange={(event) => { setAddress(event.target.value) }} />
                        <div style={{ fontSize: 12, color: 'rgba(0,0,0,0.5)', paddingTop: 5 }}>该地址仅为小程序展示地址，如需修改地图地址请联系客服修改</div>
                    </div>

                    <div style={{ paddingBottom: 20 }}>
                        <TextField id="outlined-basic" label="面积(m²)" variant="outlined" value={shop_area} onChange={(event) => { setArea(event.target.value) }} />
                    </div>
                    <div style={{ paddingBottom: 20 }}>
                        <TextField id="outlined-basic" label="容纳人数" variant="outlined" value={shop_num} onChange={(event) => { setNum(event.target.value) }} />
                    </div>
                    <div style={{ paddingBottom: 20 }}>
                        <TextField id="outlined-basic" label="电话" variant="outlined" value={shop_phone} onChange={(event) => { setPhone(event.target.value) }} />
                    </div>
                </div>
            </div>
            <div style={{ paddingBottom: 20 }}>
                <Button variant="contained" style={{ background: back_color, }} size="large" onClick={updata_shop}>确认更改</Button>
            </div>
            <div style={{ paddingBottom: 10 }}>
                <div style={{ paddingBottom: 20, fontSize: 15, fontWeight: 'bold' }}>店铺最小预约时间</div>
                {/* 大于等于8 数字 */}
                <div style={{ display: 'flex' }}>
                    <div style={{ paddingRight: 10 }}>
                        <TextField id="outlined-basic" label="最小预约时间" variant="outlined" value={min_hour} onChange={(event) => { setMinhour(event.target.value) }} />
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Button variant="contained" style={{ background: back_color, }} size="large" onClick={creation_hour}>设置</Button>
                    </div>
                </div>
                <div style={{ fontSize: 12, paddingTop: 5, color: 'rgba(0,0,0,0.5)' }}>*参数总数需为数字且不能为空</div>
            </div>
            {/* <Divider /> */}
            <div style={{ paddingBottom: 10 }}>
                <div style={{ paddingBottom: 20, fontSize: 15, fontWeight: 'bold' }}>店铺设备绑定（非必填）</div>
                {/* 大于等于8 数字 */}
                <div style={{ display: 'flex' }}>
                    <div style={{ paddingRight: 10 }}>
                        <TextField id="outlined-basic" label="设备参数" variant="outlined" value={equipment_code} onChange={(event) => { setEquipment(event.target.value) }} />
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Button variant="contained" style={{ background: back_color, }} size="large" onClick={creation}>绑定</Button>
                    </div>
                </div>
                <div style={{ fontSize: 12, paddingTop: 5, color: 'rgba(0,0,0,0.5)' }}>*参数总数需大于8并且为数字</div>
            </div>
            <Divider />
            <div>
                <div style={{ fontSize: 14, fontWeight: 'bold', paddingBottom: 20 }}>创建房间</div>
                {/* <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId="droppable" direction="grid">
                        {(provided, snapshot) => (
                            <div
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                                style={getListStyle(snapshot.isDraggingOver)}
                            >
                                {room_sort.map((item, index) => (
                                    <Draggable key={item.id} draggableId={'lists'+item.id} index={index}>
                                        {(provided, snapshot) => (
                                            <div
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                                style={getItemStyle(
                                                    snapshot.isDragging,
                                                    provided.draggableProps.style
                                                )}
                                            >
                                                <Link to={{ pathname: '/room_show/' + item.id, state: { shop_name: shop_name } }} key={index}><div style={{ boxShadow: '0 0 10px rgba(0, 0, 0, 0.16)', padding: 10, marginRight: 20, marginBottom: 20 }}>
                                <div>
                                    <img src={item.cover_url} style={{ width: 120, height: 160 }} alt="" />
                                </div>
                                <div style={{ fontSize: 12, textAlign: 'center' }}>{item.room_name}</div>
                            </div>
                            </Link>
                                            </div>
                                        )}
                                    </Draggable>
                                ))}
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext> */}
                <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                    {
                        room_lists.map((item, index) =>
                            <Link to={{ pathname: '/room_show/' + item.id, state: { shop_name: shop_name } }} key={index}><div style={{ boxShadow: '0 0 10px rgba(0, 0, 0, 0.16)', padding: 10, marginRight: 20, marginBottom: 20 }}>
                                <div style={{position:'relative'}}>
                                    <img src={item.cover_url} style={{ width: 120, height: 160 }} alt="" />
                                    <div style={{position:'absolute',left:0,bottom:0,background:back_color,width:'100%',fontSize:13,textAlign:'center',color:'#fff'}}>{
                                        item.room_online?'已上线':'已下线'
                                    }</div>
                                </div>
                                <div style={{ fontSize: 12, textAlign: 'center' }}>{item.room_name}</div>
                            </div>
                            </Link>
                        )
                    }
                    <Link to={{ pathname: '/create_room/' + shop_id, state: { shop_name: shop_name } }}>
                        <div style={{ boxShadow: '0 0 10px rgba(0, 0, 0, 0.16)', padding: 10, marginRight: 20, marginBottom: 20 }}>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <AddIcon style={{ width: 120, height: 180 }} />
                            </div>
                        </div>
                    </Link>
                </div>
            </div>
            <Divider />
            <div style={{ display: 'flex' }}>
                <div style={{ padding: '30px 0' }}>
                    <Button variant="contained" style={{ background: back_color, }} size="large" onClick={go_online}>上线店铺</Button>
                </div>
                <div style={{ padding: '30px' }}>
                    <Button variant="contained" style={{ background: back_color, }} size="large" onClick={is_delete_shop}>删除店铺</Button>
                </div>
            </div>
            <Dialog
                open={is_delete}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"确定要删除该店铺吗?"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        店铺信息与该店铺所有房间信息将全部删除，无法恢复
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>取消</Button>
                    <Button onClick={delete_shop} autoFocus>
                        确认
                    </Button>
                </DialogActions>
            </Dialog>
            <Snackbar open={is_shop_open} autoHideDuration={3000} onClose={alert_handleClose} anchorOrigin={{ vertical, horizontal }}>
                <Alert icon={false} onClose={alert_handleClose} severity="success" sx={{ width: '100%' }}>
                    {is_shop_binding ? '绑定成功' : '绑定失败'}
                </Alert>
            </Snackbar>
            <Snackbar open={is_online_shop} autoHideDuration={3000} onClose={online_handleClose} anchorOrigin={{ vertical, horizontal }}>
                <Alert icon={false} onClose={online_handleClose} severity="success" sx={{ width: '100%' }}>
                    {online_err}
                </Alert>
            </Snackbar>
            <Snackbar open={is_amend} autoHideDuration={1500} onClose={amend_handleClose} anchorOrigin={{ vertical, horizontal }}>
                <Alert icon={false} onClose={amend_handleClose} severity="success" sx={{ width: '100%' }}>
                    {amend_state ? '修改成功' : '修改失败'}
                </Alert>
            </Snackbar>
        </div>
    )
}

export default ShopShow;