import React, { useEffect } from "react";
import { back_color } from '../../configs/constants';
import { useDispatch, useSelector } from "react-redux";
import { Link, Route, Switch, useHistory, useLocation } from "react-router-dom";
import Box from '@mui/material/Box';
import Storefront from './storefront'
import Equipment from './equipment'
import UsersData from './users_data'
import Personnel from './personnel'
import CreateShop from './createShop'
import ShopShow from "./shopShow";
import CreateRoom from './createRoom';
import RoomShow from './roomShow';
import Marketing from './marketing';
import { logout } from '../../actions/user'

const _ = require('micro-dash');
function Home() {
    const dispatch = useDispatch();
    const location = useLocation();
    const history = useHistory();
    const [lefTab, setlefTab] = React.useState(0);
    const token = useSelector((state) => state.user.token);
    useEffect(() => {
        // dispatch(web_qr_code({mini_type:1,coupon_uid:null,level:1}))
        switch (location.pathname) {
            case "/":
                setlefTab(0);
                break;
            case "/equipment":
                setlefTab(1);
                break;
            case "/marketing":
                setlefTab(2);
                break;
            case "/users_data":
                setlefTab(3);
                break;
            case "/personnel":
                setlefTab(4);
                break;
            default:
                setlefTab(0);
        }
    }, [location.pathname]);
    useEffect(() => {
        // dispatch(web_qr_code({mini_type:1,coupon_uid:null,level:1}))
        switch (location.pathname) {
            case "/":
                setlefTab(0);
                break;
            case "/equipment":
                setlefTab(1);
                break;
            case '/marketing':
                setlefTab(2);
                break;
            case "/users_data":
                setlefTab(3);
                break;
            case "/personnel":
                setlefTab(4);
                break;
            default:
                setlefTab(0);
        }
    }, []);

    const drop_out_login = () => {
        window.localStorage.clear()
        dispatch(logout())
        history.replace('/login')
    }
    useEffect(() => {
        if (_.isNil(token)) {
            history.replace('/login')
        }
    }, [token])
    return (
        <div style={{ display: 'flex', }}>
            <div style={{ minHeight: '100vh', width: '10vw', position: 'relative', minWidth: '100px' }}>
                <div style={{ height: 'calc(80% - 1px)', borderBottom: '1px solid rgba(0,0,0,0.1)' }}>
                    {/* <Link to='/'> */}
                    <div style={{ display: 'flex', padding: 20, cursor: "pointer", paddingRight: 0, paddingTop: 15, paddingBottom: 8 }}>
                        <div>
                            {/* <img style={{width:30,height:30}} src={logo} alt='' /> */}
                        </div>
                        <div style={{ paddingLeft: 10, lineHeight: '30px', fontSize: '18px', fontWeight: 'bold' }}>麻麻来了</div>
                    </div>
                    {/* </Link> */}
                    <Link to='/'>
                        <div onClick={() => setlefTab(0)} style={{ cursor: "pointer", display: 'flex', padding: '8px 20px', margin: '10px 0', background: lefTab === 0 ? back_color : '' }}>
                            <div>
                                {/* <img src={lefTab===0?member_icon:member_icon_selected} alt='' style={{width:20,height:20,paddingTop:2}} /> */}
                            </div>
                            <div style={{ paddingLeft: 10, fontSize: 14, color: lefTab === 0 ? '#fff' : '' }}>店面</div>
                        </div>
                    </Link>
                    <Link to='/equipment'>
                        <div onClick={() => setlefTab(1)} style={{ cursor: "pointer", display: 'flex', padding: '8px 20px', margin: '10px 0', background: lefTab === 1 ? back_color : '' }}>
                            <div>
                                {/* <img src={lefTab===1?pay_icon:pay_icon_selected} alt='' style={{width:20,height:20,paddingTop:2}} /> */}
                            </div>
                            <div style={{ paddingLeft: 10, fontSize: 14, color: lefTab === 1 ? '#fff' : '' }}>设备</div>
                        </div>
                    </Link>
                    <Link to='/marketing'>
                        <div onClick={() => setlefTab(2)} style={{ cursor: "pointer", display: 'flex', padding: '8px 20px', margin: '10px 0', background: lefTab === 2 ? back_color : '' }}>
                            <div style={{ paddingLeft: 10, fontSize: 14, color: lefTab === 2 ? '#fff' : '' }}>营销</div>
                        </div>
                    </Link>
                    <Link to='/users_data'>
                        <div onClick={() => setlefTab(3)} style={{ cursor: "pointer", display: 'flex', padding: '8px 20px', margin: '10px 0', background: lefTab === 3 ? back_color : '' }}>
                            <div>
                                {/* <img src={lefTab===2?my_questions_icon:my_questions_icon_selected} alt='' style={{width:20,height:20,paddingTop:2}} /> */}
                            </div>
                            <div style={{ paddingLeft: 10, fontSize: 14, color: lefTab === 3 ? '#fff' : '' }}>数据</div>
                        </div>
                    </Link>
                    <Link to='/personnel'>
                        <div onClick={() => setlefTab(4)} style={{ cursor: "pointer", display: 'flex', padding: '8px 20px', margin: '10px 0', background: lefTab === 4 ? back_color : '' }}>
                            <div>
                                {/* <img src={lefTab===2?my_questions_icon:my_questions_icon_selected} alt='' style={{width:20,height:20,paddingTop:2}} /> */}
                            </div>
                            <div style={{ paddingLeft: 10, fontSize: 14, color: lefTab === 4 ? '#fff' : '' }}>人员</div>
                        </div>
                    </Link>
                </div>
                <div style={{ width: '100%', textAlign: 'right', height: '20%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <div>
                        <div onClick={drop_out_login} style={{ padding: '8px 10px', background: back_color, color: '#fff', borderRadius: 8, cursor: "pointer", margin: '0 auto', textAlign: 'center', fontSize: '13px' }}>退出登录</div>
                    </div>
                </div>
            </div>
            <div style={{ width: '90vw', height: '100vh', overflowY: 'auto' }}>
                <div style={{ padding: '40px 50px', background: 'rgba(204,153,204,0.1)', minHeight: 'calc(100vh - 80px)' }}>
                    <Switch>
                        <Route exact path="/" render={(props) => <Storefront {...props} />} />
                        <Route path="/equipment" render={(props) => <Equipment {...props} />} />
                        <Route path="/users_data" render={(props) => <UsersData {...props} />} />
                        <Route path="/personnel" render={(props) => <Personnel {...props} />} />
                        <Route path="/create_shop" render={(props) => <CreateShop {...props} />} />
                        <Route path="/marketing" render={(props) => <Marketing {...props} />} />
                        <Route path="/shop_show/:shop_id" render={(props) => <ShopShow {...props} />} />
                        <Route path="/create_room/:shop_id" render={(props) => <CreateRoom {...props} />} />
                        <Route path="/room_show/:room_id" render={(props) => <RoomShow {...props} />} />
                    </Switch>
                </div>
            </div>
        </div>
    )
}
export default Home;