import {SHOP_LISTS,LOCATION,SHOP_DATA,ROOM_LISTS,IS_CREATE_ROOM,ROOM_DATA,IS_CREATE_SHOP,IS_SHOP_OPEN,IS_ROOM_OPEN,IS_DETAIL_ROOMS,IS_ROOM_BINDING,IS_DETAIL_SHOPS,IS_SHOP_BINDING,IS_LOADING,ONLINE_ERR,
    IS_ONLINE_SHOP,IS_PRICE,PRICE_STATE, HIDE_MAP,ADDRESS_JUDGE,MAP_ERR,IS_AMEND,AMEND_STATE,IS_ONLINE_ROOM,IS_ONLINE_STATE
} from '../actions/types';
import { isUndefined } from 'micro-dash';
import { act } from 'react-dom/test-utils';
const _ = require('micro-dash');

const initialState={
    shop_lists:[],
    location:null,
    shop_data:{
        name:'',
        addr:'',
        area:'',
        capacity:'',
        phone_number:'',
        cover_url:'',
        other_img:[],
        device_code:null
    },
    room_lists:[],
    is_create_room:null,
    room_data:{
        room_name:'',
        tag:[],
        price:'',
        package_price:'',
        cover_url:'',
        device_code:{
            code:''
        },
        shop:{}
    },
    is_create_shop:true,
    is_shop_open:false,
    is_room_open:false,
    examine_address:false,
    is_detail_room:false,
    is_room_binding:false,
    is_detail_shop:false,
    is_shop_binding:false,
    is_loading:false,
    online_err:'',
    is_online_shop:false,
    address_judge:null,
    is_price:false,
    price_state:false,
    show_map:false
}

// eslint-disable-next-line import/no-anonymous-default-export
export default function(state=initialState, action) {
    switch(action.type){
        case SHOP_LISTS:
            return {...state,shop_lists:action.message}
        case LOCATION:
            return {...state,location:action.location}
        case SHOP_DATA:
            return {...state,shop_data:action.data}
        case ROOM_LISTS:
            return {...state,room_lists:action.data}
        case IS_CREATE_ROOM:
            return {...state,is_create_room:action.data}
        case ROOM_DATA:
            let tag=action.data.tag
            action.data.tag=tag.split(',')
            return {...state,room_data:action.data}
        case IS_CREATE_SHOP:
            return {...state,is_create_shop:action.data}
        case IS_SHOP_OPEN:
            return {...state,is_shop_open:action.is_open}
        case IS_ROOM_OPEN:
            return {...state,is_room_open:action.is_open}
        case IS_DETAIL_ROOMS:
            return {...state,is_detail_room:action.is_detail}
        case IS_ROOM_BINDING:
            return {...state,is_room_binding:action.is_binding}
        case IS_DETAIL_SHOPS:
            return {...state,is_detail_shop:action.is_detail}
        case IS_SHOP_BINDING:
            return {...state,is_shop_binding:action.is_binding}
        case IS_LOADING:
            return {...state,is_loading:action.is_loading}
        case ONLINE_ERR:
            return {...state,online_err:action.online_err}
        case IS_ONLINE_SHOP:
            return {...state,is_online_shop:action.is_online_shop}
        case IS_PRICE:
            return {...state,is_price:action.is_price}
        case PRICE_STATE:
            return {...state,price_state:action.state}
        case HIDE_MAP:
            return {...state,show_map:action.start}
        case ADDRESS_JUDGE:
            return {...state,address_judge:action.start}
        case MAP_ERR:
            return {...state,map_err:action.start}
        case IS_AMEND:
            return {...state,is_amend:action.is_amend}
        case AMEND_STATE:
            return {...state,amend_state:action.amend_state}
        case IS_ONLINE_ROOM:
            return {...state,is_online_room:action.is_online_room}
        case IS_ONLINE_STATE:
            return {...state,online_message:action.online_message,is_online_state:action.is_online_state}
    default:
        return state;
    }
}