import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Link ,useLocation} from "react-router-dom";
import TextField from '@mui/material/TextField';
import AddIcon from '@mui/icons-material/Add';
import { PlusOutlined } from '@ant-design/icons';
import { Upload, Modal, Divider, Spin } from 'antd';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import { TMap, MultiMarker } from '@map-component/react-tmap';
import { back_color } from '../../configs/constants';
import DoneIcon from '@mui/icons-material/Done';
import Iframe from "react-iframe";
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import cover_img from '../../static/img/cover.png'
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';

import swiper_img from '../../static/img/swiper.png'

import { address, add_shops, open_snackbar_shop, is_loading, hide_map, is_address_judge,is_map_err } from '../../actions/shop'

const _ = require('micro-dash');
const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
};
// const map_key = 'OKWBZ-XNM3X-JOP4H-7GIPM-UW4GO-W4BDK'
function Createshop() {
    const dispatch = useDispatch();
    //店铺封面图片
    const [shop_img, setShopImg] = useState(false);
    const [imageUrl, setImageUrl] = useState();
    const handleChange = (info) => {
        //console.log(info)
        if (info.file.size > 1048576) {
            alert('图片大小需在1mb以内')
        } else {
            let img = new Image()
            getBase64(info.file, (url) => {
                img.src = url
                img.onload = () => {
                    //console.log(img.width)
                    let width = img.width
                    let height = img.height
                    let calculate_width = (width / 3 * 4).toFixed(2)
                    if (calculate_width * 0.9 < height && height < calculate_width * 1.1) {
                        setImageUrl(url);
                        setShopImg(info.file)
                    } else {
                        alert('图片尺寸宽高比需为3：4')
                    }
                }

            });
        }
    };
    const uploadButton = (
        <div>
            <PlusOutlined />
            <div
                style={{
                    marginTop: 8,
                }}
            >
                上传图片
            </div>
        </div>
    );

    //宣传图片
    const [previewOpen, setPreviewOpen] = useState(false);
    const [previewImage, setPreviewImage] = useState('');
    const [previewTitle, setPreviewTitle] = useState('');
    const [fileList, setFileList] = useState([]);
    const handleCancel = () => setPreviewOpen(false);
    const handleChanges = ({ fileList: newFileList }) => {
        //console.log(newFileList)
        if (newFileList[newFileList.length - 1].size > 1048576) {
            alert('图片大小需在1mb以内')
        } else {
            let img = new Image()
            getBase64(newFileList[newFileList.length - 1].originFileObj, (url) => {
                img.src = url
                img.onload = () => {
                    let width = img.width
                    let height = img.height
                    let calculate_width = (width / 5 * 3).toFixed(2)
                    if (calculate_width * 0.9 < height && height < calculate_width * 1.1) {
                        setFileList(newFileList)
                    } else {
                        alert('图片尺寸不对')
                    }
                }
            });
        }

    };

    const modalImportOk = () => {
        //console.log(fileList)
    }
    // 地址确认
    const [address_value, setAddress] = useState('');
    const [map_value,setMapvalue]=useState('');
    const address_judge = useSelector((state) => state.shop.address_judge);
    const location = useSelector((state) => state.shop.location);
    const site_confirm = () => {
        if (map_value === '') {
            alert('搜索不能未空')
        } else {
            dispatch(address(map_value))
        }
    }
    const [state, setState] = React.useState({
        vertical: 'top',
        horizontal: 'center',
    });
    const { vertical, horizontal } = state;

    const alert_handleClose = () => {
        dispatch(open_snackbar_shop(false))
    };
    // const [show_map, setMap] = useState(false);
    const map_err = useSelector((state) => state.shop.map_err)
    const show_map = useSelector((state) => state.shop.show_map)
    const handleClose = () => {
        // setMap(false)
        dispatch(hide_map(false))
    }
    const map_creation = () => {
        if(lat === 28.195268 && lng === 112.976473){
            alert('您未选择地点')
        }else{
            dispatch(is_address_judge(true))
            dispatch(hide_map(false))
            dispatch(is_map_err(true))
            setEndLat(lat)
            setEndLng(lng)
        }
    }
    const map_confirm=()=>{
        if(address_value===''){
            alert('请先输入展示地址')
        }else{
            dispatch(is_loading(true))
            dispatch(address(map_value))
        }
    }
    const [end_lat, setEndLat] = useState(28.195268);
    const [end_lng, setEndLng] = useState(112.976473);

    const [zoom, setZoom] = useState(16);
    const [lat, setLat] = useState(28.195268);
    const [lng, setLng] = useState(112.976473);
    const [center, setCenter] = useState({ lat: lat, lng: lng });
    // let center = { lat: lat, lng: lng };
    const [loc, setLoc] = useState({ ...center });
    const geometries = [{ position: { ...loc } }]
    useEffect(() => {
        //console.log(location)
        if (location != null) {
            setLat(location.lat)
            setLng(location.lng)
            setCenter({ lat: location.lat, lng: location.lng })
            setLoc({ lat: location.lat, lng: location.lng })
        }
    }, [dispatch, location])
    useEffect(()=>{
        dispatch(is_address_judge(null))
    },[])
    //提交
    const [shop_value, setShopValue] = useState('');
    const [area_value, setArea] = useState('');
    const [number_value, setNumber] = useState('');
    const [phone_value, setPhone] = useState('');
    const is_create_shop = useSelector((state) => state.shop.is_create_shop);
    const loading = useSelector((state) => state.shop.is_loading)
    const is_shop_open = useSelector((state) => state.shop.is_shop_open);
    const creation = () => {
        //console.log(address_judge,end_lat,end_lng)
        if (shop_value === '') {
            alert('店铺名不能为空')
        } else if ( address_value === '') {
            alert('地址格式错误')
        }else if(!address_judge){
            alert('地图未选点')
        } else if ((area_value > 1000 || area_value < 10) && (typeof area_value === 'number' && area_value % 1 === 0)) {
            alert('面积范围10m²-1000m²且为整数')
        } else if ((number_value > 100 || number_value < 1) && (typeof number_value === 'number' && number_value % 1 === 0)) {
            alert('容纳人数需在1-100人内')
        } else if (phone_value.length < 5) {
            alert('请输入正确的手机号')
        } else if (_.isNil(imageUrl)) {
            alert('店铺展示图片不能为空')
        } else if (fileList.length === 0) {
            alert('店铺介绍图片至少需上传一张')
        } else {
            let formData = new FormData()
            formData.append('name', shop_value)
            formData.append('addr', address_value)
            formData.append('area', area_value)
            formData.append('capacity', number_value)
            formData.append('phone_number', phone_value)
            formData.append('file', shop_img)
            let formDatas = new FormData()
            fileList.forEach((item, index) => {
                //console.log(item)
                formDatas.append('file', item.originFileObj);
            })
            formDatas.append('is_shop', 1)
            formDatas.append('pid', 2)
            //console.log(location)
            dispatch(add_shops({ data: formData, img_lists: formDatas, location_message: { longitude: end_lng, latitude: end_lat, address: address_value } }))
            // dispatch(shop_location())
            dispatch(is_loading(true))
        }
    }
    const handleClick=()=>{}
    return (
        <div style={{ padding: 20, textAlign: 'left' }}>
            <div role="presentation" onClick={handleClick} style={{paddingBottom:'3%'}}>
                <Breadcrumbs aria-label="breadcrumb">
                    <Link underline="hover" color="inherit"  to= {{ pathname: '/' }} style={{color:'#646060'}}>
                        首页
                    </Link>
                    <Typography color="text.primary" style={{fontWeight:'bold'}}>创建店铺</Typography>
                </Breadcrumbs>
            </div>
            <Spin spinning={loading}>
                <div style={{ marginBottom: 20, display: 'flex', justifyContent: 'space-between' }}>
                    <div>
                        <div style={{ paddingBottom: 20 }}>
                            <div style={{ fontSize: 12, paddingBottom: 8 }}>店铺展示图片(比例：3：4)</div>
                            <Upload
                                {...modalImportOk}
                                name="avatar"
                                listType="picture-card"
                                className="avatar-uploader"
                                showUploadList={false}
                                action=""
                                maxCount='1'
                                onChange={(info) => { handleChange(info) }}
                                beforeUpload={() => {
                                    //阻止上传
                                    return false;
                                }}
                            >
                                {imageUrl ? (
                                    <img
                                        src={imageUrl}
                                        alt="avatar"
                                        style={{
                                            height: '100%',
                                            width: '80%'
                                        }}
                                    />
                                ) : (
                                    uploadButton
                                )}
                            </Upload>
                        </div>
                        <div>
                            <div style={{ fontSize: 12, paddingBottom: 8 }}>店铺介绍图片(比例：5：3，多张)</div>
                            <Upload
                                {...modalImportOk}
                                listType="picture-card"
                                fileList={fileList}
                                beforeUpload={() => {
                                    //阻止上传
                                    return false;
                                }}
                                onChange={(info) => { handleChanges(info) }}
                                action=''
                            >
                                {fileList.length >= 5 ? null : uploadButton}
                            </Upload>
                            <Modal open={previewOpen} title={previewTitle} footer={null} onCancel={handleCancel}>
                                <img
                                    alt="example"
                                    style={{
                                        width: '80%',
                                        height: '100%'
                                    }}
                                    src={previewImage}
                                />
                            </Modal>
                        </div>
                    </div>
                    <div style={{ display: 'flex' }}>
                        <div>
                            <div style={{ fontSize: 12, paddingBottom: 10, textAlign: 'center' }}>店铺展示示例图片</div>
                            <div>
                                <img src={cover_img} style={{ width: 150, height: 300 }} alt='' />
                            </div>
                        </div>
                        <div style={{ paddingLeft: 30 }}>
                            <div style={{ fontSize: 12, paddingBottom: 10, textAlign: 'center' }}>店铺介绍示例图片</div>
                            <div>
                                <img src={swiper_img} style={{ width: 150, height: 300 }} alt='' />
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{ paddingBottom: 20 }}>
                    <TextField id="outlined-basic" required style={{ width: '25%' }} label="店铺名" variant="outlined" value={shop_value} onChange={(event) => { setShopValue(event.target.value) }} />
                </div>
                <div style={{ paddingBottom: 20, width: '35%' }}>
                    <div style={{ display: 'flex', justifyContent: 'left' }}>
                        <div style={{ width: '70%' }}>
                            <TextField id="outlined-basic" required style={{ width: '100%' }} label="详细地址" variant="outlined" value={address_value} onChange={(event) => { setAddress(event.target.value);setMapvalue(event.target.value) }} />
                        </div>

                        <div style={{ display: 'flex', alignItems: 'center', paddingLeft: 10, width: '30%' }}>
                            <Button variant="contained" style={{ background: back_color }} onClick={map_confirm}>地图选点</Button>
                            {
                                map_err ?
                                    address_judge ? <div style={{ display: 'flex', alignItems: 'center', paddingLeft: 10 }}>
                                        <CheckCircleIcon style={{ color: back_color }} />
                                    </div> : <div style={{ display: 'flex', alignItems: 'center', paddingLeft: 10 }}>
                                        <CancelIcon style={{ color: 'red' }} />
                                    </div> : ''
                            }
                        </div>

                    </div>
                    <div style={{ fontSize: 12, color: 'rgba(0,0,0,0.5)', paddingTop: 5 }}>此地址即为小程序展示地址，地图地址请在地图弹窗中选择。 例：xx省xx市xx区xx街道xx号xx单元xxx号</div>
                </div>
                <div style={{ paddingBottom: 20 }}>
                    <TextField id="outlined-basic" required style={{ width: '25%' }} label="面积(m²)" variant="outlined" value={area_value} onChange={(event) => { setArea(event.target.value) }} />
                </div>
                <div style={{ paddingBottom: 20 }}>
                    <TextField id="outlined-basic" required style={{ width: '25%' }} label="容纳人数" variant="outlined" value={number_value} onChange={(event) => { setNumber(event.target.value) }} />
                </div>
                <div style={{ paddingBottom: 20 }}>
                    <TextField id="outlined-basic" required style={{ width: '25%' }} label="电话" variant="outlined" value={phone_value} onChange={(event) => { setPhone(event.target.value) }} />
                </div>
                <div>
                    <Button variant="contained" style={{ background: back_color, width: '25%' }} size="large" onClick={creation}>创建</Button>
                </div>
                <div style={{ fontSize: 12, color: 'rgba(0,0,0,0.5)', paddingTop: 10 }}>*创建完成后，店铺处于预上线状态，需创建房间且绑定房间设备码后方可正式上线店铺</div>
                <Divider />
                <Snackbar open={is_shop_open} autoHideDuration={3000} onClose={alert_handleClose} anchorOrigin={{ vertical, horizontal }}>
                    <Alert icon={false} onClose={alert_handleClose} severity="success" sx={{ width: '100%' }}>
                        {!is_create_shop ? '创建失败' : '创建成功'}
                    </Alert>
                </Snackbar>
            </Spin>
            <Dialog onClose={handleClose} open={show_map} style={{ height: '100%' }} fullWidth='100%' fullHeight='100%'>
                <DialogContent style={{ height: '100vh', padding: '5%',paddingTop:0 }}>
                    <div style={{ height: '100%' }}>
                        <p style={{ textAlign: 'center' }}>地址选点</p>
                        <div style={{ display: 'flex', justifyContent: 'left', paddingBottom: 10 }}>
                            <div style={{ width: '70%' }}>
                                <TextField id="outlined-basic" style={{ width: '100%' }} label="请输入地址" variant="outlined" size="small" value={map_value} onChange={(event) => { setMapvalue(event.target.value) }} />
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center', paddingLeft: 10, width: '30%' }}>
                                <Button variant="contained" style={{ background: back_color }} onClick={site_confirm}>搜索</Button>
                            </div>
                        </div>
                        <div style={{ height: '75%' }}>
                            <TMap
                                mapKey="OKWBZ-XNM3X-JOP4H-7GIPM-UW4GO-W4BDK" // The applied key
                                zoom={zoom}
                                center={loc} // set the center point coordinates
                                control={{
                                    zoom: { position: 'BOTTOM_RIGHT' },
                                    scale: false,
                                    rotate: false,
                                }}
                                style={{ height: '100%' }}
                                onClick={(evt) => {
                                    // alert(`${evt.latLng.getLat().toFixed(6)}, ${evt.latLng.getLng().toFixed(6)}`);
                                    setLoc({ lat: evt.latLng.getLat().toFixed(6), lng: evt.latLng.getLng().toFixed(6) })
                                    setLat(evt.latLng.getLat())
                                    setLng(evt.latLng.getLng())
                                }}
                            >
                                <MultiMarker geometries={geometries} />
                            </TMap>
                        </div>
                        <div style={{ textAlign: 'center', paddingTop: 20 }}>
                            <Button variant="contained" style={{ background: back_color, width: '25%' }} size="large" onClick={map_creation}>确认</Button>
                            <div style={{ fontSize: 13, paddingTop: 10, color: 'rgba(0,0,0,0.5)' }}>您必须点确认按钮才能获得最新选择的位置信息</div>
                        </div>
                    </div>
                </DialogContent>
            </Dialog>
        </div>
    )
}
export default Createshop;