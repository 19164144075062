import { SHOP_LISTS,FACILITY_DATA,LOADINGN_OPEN,IS_EQ_OPEN,ERR_MESSAGE} from './types';
import { MIDDLE_EARTH_URL } from '../configs/constants';
import axios from 'axios';
const _ = require('micro-dash');
const $http=axios.create();
$http.interceptors.request.use(config => {
    config.headers.Authorization='token ' + localStorage.getItem('token')
    return config
})

export function shops(message){
    return {
        type:SHOP_LISTS,
        message
    }
}
export function facility_data(data){
    return {
        type:FACILITY_DATA,
        data
    }
}
export function facility_loading(loading){
    return {
        type:LOADINGN_OPEN,
        loading
    }
}
export function is_eq_open(open){
    return {
        type:IS_EQ_OPEN,
        open
    }
}
export function err_message(message){
    return {
        type:ERR_MESSAGE,
        message
    }
}

//店铺列表
export function shops_lists(){
    return (dispatch, getState)=>{
        $http.get(`${MIDDLE_EARTH_URL}/api/shop/get_shops_by_user/`,
            {
                
            },
            {
                headers:{
                    'content-type': 'application/json', // 默认值
                    'Authorization':'Token '+localStorage.getItem('token')
                }
            }).then(
                response=>{
                    // //console.log(response)
                    dispatch(simple_room_list(response.data[0].id))
                    dispatch(shops(response.data))
                }
            )
    }
}

//设备页数据
export function simple_room_list(shop_id){
    return (dispatch, getState)=>{
        $http.get(`${MIDDLE_EARTH_URL}/api/shop/simple_room_list/?shop_id=`+shop_id+'&include_device_is_online=true',
            {
                
            },
            {
                headers:{
                    'content-type': 'application/json', // 默认值
                    'Authorization':'Token '+localStorage.getItem('token')
                }
            }).then(
                response=>{
                    // //console.log(response)
                    dispatch(facility_data(response.data))
                }
            )
    }
}

//仅开门 mode为1 仅开门 0 开门+通电
export function only_open_door(room_id,mode){
    return (dispatch,getState)=>{
        axios.post(`${MIDDLE_EARTH_URL}/api/shop/open_room_for_cleaner_or_owner/`,{
            room_id:room_id,
            mode:mode
        },{
            headers:{
                'Authorization':'token '+localStorage.getItem('token')
            }
        }).then(response=>{
            // //console.log(response)
            dispatch(is_eq_open(true))
            dispatch(err_message(true))
            dispatch(facility_loading(false))
        }).catch(err=>{
            // //console.log(err)
            dispatch(is_eq_open(true))
            dispatch(err_message(false))
            dispatch(facility_loading(false))
        })
    }
}

//设备清零
export function close_device(device_code,room_id){
    let url=`${MIDDLE_EARTH_URL}/api/shop/close_device/?device_code=`+device_code+'&room_id='+room_id
    if(_.isNil(room_id)){
        url=`${MIDDLE_EARTH_URL}/api/shop/close_device/?device_code=`+device_code
    }
    return (dispatch, getState)=>{
        $http.get(url,
            {
                
            },
            {
                headers:{
                    'content-type': 'application/json', // 默认值
                    'Authorization':'Token '+localStorage.getItem('token')
                }
            }).then(
                response=>{
                    // //console.log(response)
                    dispatch(err_message(true))
                    dispatch(is_eq_open(true))
                    dispatch(facility_loading(false))
                }
            ).catch(err=>{
                dispatch(err_message(false))
                dispatch(is_eq_open(true))
                dispatch(facility_loading(false))
            })
    }
}

//开大门
export function open_cleaner(room_id){
    return (dispatch,getState)=>{
        axios.post(`${MIDDLE_EARTH_URL}/api/shop/open_shop_door_for_cleaner_or_owner/`,{
            room_id:room_id
        },{
            headers:{
                'Authorization':'token '+localStorage.getItem('token')
            }
        }).then(response=>{
            //console.log(response)
            dispatch(is_eq_open(true))
            dispatch(err_message(true))
            dispatch(facility_loading(false))
        }).catch(err=>{
            //console.log(err)
            dispatch(err_message(false))
            dispatch(is_eq_open(true))
            dispatch(facility_loading(false))
        })
    }
}