import { combineReducers } from 'redux';
import user from './user';
import shop from './shop';
import payment_data  from './payment_data';
import facility from './facility';
import staff from './staff'

const rootReducer = combineReducers({
    user,
    shop,
    payment_data,
    facility,
    staff
});

export default rootReducer;