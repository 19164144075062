import React from "react";
import {Route, Switch} from "react-router-dom";
import './App.css';

import Login from "./components/web/login";
import Home from "./components/web/home";
import storefront from './components/web/storefront';
import equipment from './components/web/equipment';
import users_data from './components/web/users_data';
import personnel from './components/web/personnel';
import create_shop from "./components/web/createShop";

function App() {
  return (
      <Switch>
        <Route exact path='/login' component={Login}></Route>
        <Route  path='/' component={Home}></Route>
      </Switch>
  );
}

export default App;
