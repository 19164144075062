import { LOGIN,LOGOUT} from './types';
import { MIDDLE_EARTH_URL } from '../configs/constants';
import axios from 'axios';
const _ = require('micro-dash');


export function login_success(token){
    return {
        type:LOGIN,
        token
    }
}
export function logout() {
    return {
        type: LOGOUT,
    }
}

export function login(username, password){
    return (dispatch, getState)=>{
        axios.post(`${MIDDLE_EARTH_URL}/api/user/shop_login/`,
            {
                username, password
            }).then(
                response=>{
                    // console.log(response)
                    dispatch(login_success(response.data.token))
                }
            )
    }
}