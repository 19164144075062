
import React, { useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Redirect } from "react-router-dom";
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { back_color } from '../../configs/constants';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { shops_lists, only_open_door, close_device, open_cleaner, facility_loading, is_eq_open, simple_room_list } from '../../actions/facility'
const _ = require('micro-dash');
function createData(name, calories, fat, carbs, protein) {
    return { name, calories, fat, carbs, protein };
}

const rows = [
    createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
    createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
    createData('Eclair', 262, 16.0, 24, 6.0),
    createData('Cupcake', 305, 3.7, 67, 4.3),
    createData('Gingerbread', 356, 16.0, 49, 3.9),
];


function Equipment() {
    const dispatch = useDispatch();
    const token = useSelector((state) => state.user.token);
    useEffect(() => {
        if (_.isNil(token)) {
            return <Redirect to={'/login'} />;
        } else {
            dispatch(shops_lists())
        }
        // dispatch(payment_data(1,41))
    }, []);
    const shops = useSelector((state) => state.payment_data.shop_lists);
    //console.log(shops)
    const [shop, setShop] = React.useState('');
    const handleChange = (event) => {
        //console.log(event)
        setShop(event.target.value);
        dispatch(simple_room_list(event.target.value))
    };
    useEffect(() => {
        //console.log(shops)
        if (shops.length > 0) {
            setShop(shops[0].id);
        }
    }, [dispatch, shops]);
    const facility_lists = useSelector((state) => state.facility.facility_lists);

    const [open, setOpen] = React.useState(false);
    const [device_code, setCode] = React.useState(null);
    const [shop_id,setShopId]=React.useState(null);
    const loading_open = useSelector((state) => state.facility.loading_open);
    // const [loading_open, setLoading] = React.useState(false);
    const handleClickOpen = (e,e1) => {
        setCode(e)
        setShopId(e1)
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    const affirm = () => {
        setOpen(false);
        dispatch(facility_loading(true))
        dispatch(close_device(device_code,shop_id))
    }
    const [only_open,setOnlyopen]=React.useState(false);
    const only_handleClickOpen = (e) => {
        setCode(e)
        // setShopId(e1)
        setOnlyopen(true);
    };
    const only_handleClose=()=>{
        setOnlyopen(true);
    }
    const only_affirm = () => {
        setOnlyopen(false);
        dispatch(facility_loading(true))
        dispatch(close_device(device_code))
    }

    const [state, setState] = React.useState({
        vertical: 'top',
        horizontal: 'center',
    });
    const { vertical, horizontal } = state;
    const eq_open = useSelector((state) => state.facility.eq_open);
    const err_message = useSelector((state) => state.facility.err_message);
    const alert_handleClose = () => {
        dispatch(is_eq_open(false))
    }
    return (
        <div>
            <div style={{ display: 'flex', paddingBottom: 10 }}>
                {/* <div style={{display:'flex',alignItems:'center',fontSize:14}}>店铺：</div> */}
                <div>
                    <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">店铺</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={shop}
                            label="Age"
                            onChange={handleChange}
                        >
                            {
                                shops.map((item, index) =>
                                    <MenuItem value={item.id} key={index}>{item.name}</MenuItem>
                                )
                            }
                        </Select>
                    </FormControl>
                </div>
            </div>
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell align="center">房间名</TableCell>
                            <TableCell align="center">房间设备码</TableCell>
                            <TableCell align="center">在线/不在线</TableCell>
                            {/* <TableCell align="center">房间状态</TableCell> */}
                            <TableCell align="center">仅开房间门</TableCell>
                            <TableCell align="center">开房间门+通电15分钟</TableCell>
                            <TableCell align="center">清零+删除预约</TableCell>
                            <TableCell align="center">设备清零</TableCell>
                            <TableCell align="center">店铺名</TableCell>
                            <TableCell align="center">店铺设备码</TableCell>
                            <TableCell align="center">开店铺大门</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {facility_lists.map((row, index) => (
                            <TableRow
                                key={index}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell align="center" component="th" scope="row">
                                    {row.room_name}
                                </TableCell>
                                <TableCell align="center">{row.device_code.code}</TableCell>
                                <TableCell align="center">{row.room_online ? '在线' : '不在线'}</TableCell>
                                {/* <TableCell align="center">{row.status||row.room_is_available ? '在线' : '不在线'}</TableCell> */}
                                <TableCell align="center">
                                    <Button variant="contained" onClick={() => { dispatch(only_open_door(row.id, 1)); dispatch(facility_loading(true)) }} style={{ backgroundColor: back_color }}>开门</Button>
                                </TableCell>
                                <TableCell align="center">
                                    <Button variant="contained" onClick={() => { dispatch(only_open_door(row.id, 0)); dispatch(facility_loading(true)) }} style={{ backgroundColor: back_color }}>开门+通电</Button>
                                </TableCell>
                                <TableCell align="center">
                                    <Button variant="contained" onClick={() => handleClickOpen(row.device_code.code,row.id)} style={{ backgroundColor: back_color }}>清零+删除预约</Button>
                                </TableCell>
                                <TableCell align="center">
                                    <Button variant="contained" onClick={() => only_handleClickOpen(row.device_code.code)} style={{ backgroundColor: back_color }}>仅清零</Button>
                                </TableCell>
                                <TableCell align="center">{row.shop_name}</TableCell>
                                <TableCell align="center">{row.shop_device_code}</TableCell>
                                <TableCell align="center">
                                    <Button variant="contained" onClick={() => { dispatch(open_cleaner(row.id)); dispatch(facility_loading(true)) }} style={{ backgroundColor: back_color }}>开大门</Button>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"清空设备"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        您确认要清空设备+删除预约吗？
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>取消</Button>
                    <Button onClick={affirm} autoFocus>
                        确认
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={only_open}
                onClose={only_handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"清空设备"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        您确认要清空设备吗？
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>取消</Button>
                    <Button onClick={only_affirm} autoFocus>
                        确认
                    </Button>
                </DialogActions>
            </Dialog>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading_open}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <Snackbar open={eq_open} autoHideDuration={3000} onClose={alert_handleClose} anchorOrigin={{ vertical, horizontal }}>
                <Alert icon={false} onClose={alert_handleClose} severity="success" sx={{ width: '100%' }}>
                    {!err_message ? '执行失败' : '执行成功'}
                </Alert>
            </Snackbar>
        </div>
    )
}
export default Equipment;