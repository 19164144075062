import {LOGIN,LOGOUT} from '../actions/types';
import { isUndefined } from 'micro-dash';
const _ = require('micro-dash');

const initialState={
    token:localStorage.getItem('token'),
}

// eslint-disable-next-line import/no-anonymous-default-export
export default function(state=initialState, action) {
    switch(action.type){
        case LOGIN:
            localStorage.setItem('token', action.token)
            return {...state,token:action.token}
        case LOGOUT:
            return {
                ...state,
                token: null
            };
    default:
        return state;
    }
}