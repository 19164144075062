//user
export const LOGIN='LOGIN';
export const LOGOUT='logout'
// loading
export const IS_LOADING='is_loading'

//shop
export const SHOP_LISTS='shop_lists';
export const LOCATION='location';
export const SHOP_DATA='shop_data';
export const ROOM_LISTS='room_lists';
export const IS_CREATE_ROOM='is_create_room';
export const ROOM_DATA='room_data';
export const IS_CREATE_SHOP='is_create_shop';
export const IS_SHOP_OPEN='is_shop_open';
export const IS_ROOM_OPEN='is_room_open';
export const IS_DETAIL_ROOMS='is_detail_rooms';
export const IS_ROOM_BINDING='is_room_binding';
export const IS_DETAIL_SHOPS='is_detail_shops';
export const IS_SHOP_BINDING='is_shop_binding';
export const ONLINE_ERR='online_err';
export const IS_ONLINE_SHOP='is_online_shop';
export const ADDRESS_SNACKBAR='address_snackbar';
export const IS_PRICE='is_price';
export const PRICE_STATE='price_state'
export const LOCATION_SHOW='location_show';
export const HIDE_MAP='hide_map';
export const ADDRESS_JUDGE='address_judge';
export const MAP_ERR='map_err';
export const AMEND_STATE='amend_state';
export const IS_AMEND='is_amend';
export const IS_ONLINE_ROOM='is_online_room';
export const IS_ONLINE_STATE='is_online_state'

// facility
export const FACILITY_DATA='facility_data';
export const LOADINGN_OPEN='loading_open';
export const IS_EQ_OPEN='is_eq_open';
export const ERR_MESSAGE='err_message';

//staff
export const RELEVANCY='relevancy';
export const SNACK_OPEN='snack_open';
export const BOSS_LISTS='boss_lists';
export const CLEANERS_LISTS='cleaners_lists'

//user_data
export const DATA_LISTS='data_lists';
export const FLOW_DATA='flow_data'

//marketing
export const ACTIVITY_LISTS='activity_lists'
export const CREATION_ACTIVITY_ERR='creation_activity_err'
export const SHOW_CONSEQUENCE='show_consequence';
export const COUPONS_LISTS='coupons_lists'